import styled from "styled-components";
import Button from "../button/button.component";

export const FormButtonStyles = styled(Button)`
    background:#2196F3;
    border-radius:5px;
    border:1px solid #CCC;
    cursor:pointer;
    padding:6px 12px;
    color:#FFF;
    font-size:1.2em;
`;