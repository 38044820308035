import { useMemo, useEffect, Fragment, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { 
    selectFlags, 
    selectToken, 
    selectUploadFileDataTable, 
    selectUploadFileData, 
    selectHasUploadFileData, 
    selectIsLoading, 
    selectProjectData, 
    selectAuthError 
} from "../../store/upload-only/upload-only.selector";
import { fetchUOAuthAsync, setFileUploadData, processUploadsAsync, resetState } from "../../store/upload-only/upload-only.actions";
import { LoginBoxPage, LoginBoxStyles } from "../../components/login-box/login-box.styles";
import { setErrors, setMessagesTimeout } from "../../store/messages/messages.actions";
import { fetchFileUploadDataAsync, fetchUploadAsync, setFileData, clearNewJobComplete, setFileUploadCompleteFlag } from "../../store/project-list/project-list.actions";
import { selectProjectIsLoading, selectFileUploadInitiated, selectFileUploadComplete, selectFileUploadData, selectIsFileUploading, selectFileData } from "../../store/project-list/project-list.selector";
import { setUploadProgressModalVisible } from "../../store/aip/aip.actions";
import { filterFiles } from "../../utils/upload/upload.utils";
import ButtonText from "../../components/button-text/button-text.component";
import FormButton from "../../components/form-button/form-button.component";
import Icon from "../../components/icon/icon.component";
import Loading from "../../components/loading/loading.component";
import SectionTitle from "../../components/section-title/section-title.component";
import Form from "../../components/form/form.component";
import InputFile from "../../components/input-file/input-file.component";
import InputFileList from "../../components/input-file-list/input-file-list.component";
import RowInner from "../../components/row-inner/row-inner.component";
import ColHalf from "../../components/col-half/col-half.component";
import Progress from "../../components/progress/progress.component";

const FileUpload = () => {
    const dispatch = useDispatch();
    const { authInit, authFailed, processCompleted } = useSelector(selectFlags);
    const uploadFileDataTable = useSelector(selectUploadFileDataTable);
    const uploadFileData = useSelector(selectUploadFileData);
    const hasFileList = useSelector(selectHasUploadFileData);
    const projectData = useSelector(selectProjectData);
    const authError = useSelector(selectAuthError);
    const token = useSelector(selectToken);
    const fileUploadInitiated = useSelector(selectFileUploadInitiated);
    const fileUploadComplete = useSelector(selectFileUploadComplete);
    const fileLoading = useSelector(selectProjectIsLoading);
    const userIsLoading = useSelector(selectIsLoading) || fileLoading;
    const isFileUploading = useSelector(selectIsFileUploading);
    const fileUploadSignedData = useSelector(selectFileUploadData);
    const fileData = useSelector(selectFileData);

    const [ uploadProperties, setUploadProperties ] = useState([]);
    const uploadPropertiesRef = useRef(uploadProperties);

    const setUploadPropertiesRef = data => {
        uploadPropertiesRef.current = data;
        setUploadProperties(data);
    };
    const useQuery = () => {
        const { search } = useLocation();
        return useMemo(()=> new URLSearchParams(search),[search]);
    };
    const query = useQuery();

    const handleFileUploadProgress = (fileidx) => (progressEvent) => { 
        const {loaded, total} = progressEvent;
        const percent = Math.floor((loaded * 100)/total);
        uploadPropertiesRef.current[fileidx] = percent;
        setUploadPropertiesRef([...uploadPropertiesRef.current]);
    }
    useEffect(()=>{
        dispatch(setUploadProgressModalVisible(isFileUploading));
    },[isFileUploading, dispatch]);
    useEffect(()=>{
        if (!processCompleted) return;
        setUploadPropertiesRef([]);
        dispatch(clearNewJobComplete());
        dispatch(resetState());
        dispatch(setMessagesTimeout(["Your files were uploaded successfully. Thank you!"],5000));

    },[processCompleted]);
    useEffect(()=>{
        if (!fileUploadInitiated) return;
        const callData = uploadFileData.map((ele,idx)=>({data:ele.file,endpoint:fileUploadSignedData[idx].signed, filename:ele.filename, fileuuid:fileUploadSignedData[idx].uuid,idx,updater:handleFileUploadProgress(idx)}));
        const baseProgress = uploadFileData.map(ele=>0);
        const fileData = callData.map((ele)=>({fileuuid:ele.fileuuid,filename:ele.filename}));
        const params = {
            data:callData
        };
        setUploadPropertiesRef(baseProgress);
        dispatch(fetchUploadAsync(params));
        dispatch(setFileData(fileData));
    },[fileUploadInitiated]);

    useEffect(()=>{
        if (!fileUploadComplete) return;
        const data = fileData.map(ele=>({Key:`new/${ele.fileuuid}`,filename:ele.filename}));
        const snsdata = {
            data,
            d:query.get("d")
        }
        dispatch(setFileUploadCompleteFlag(false));
        dispatch(processUploadsAsync({data:snsdata,token}));
    },[fileUploadComplete]);
    useEffect(()=>{
        if (!authInit) return;
        const params = {
            data: {
                a:query.get("a"),
                d:query.get("d")
            },
            token:"notokenrequired",
            endpoint:"https://clients-endpoint.worldtranslationcenter.com/v1/authorize-vendor"
        };
        dispatch(fetchUOAuthAsync(params));
    },[authInit,dispatch,query]);
    
    useEffect(()=>{
        if (!authFailed) return;
        dispatch(setErrors([authError]));
    },[authError,authFailed,dispatch]);

    const handleSubmit = event => {
        event.preventDefault();
        dispatch(fetchFileUploadDataAsync({count:uploadFileData.length,token}));
    };
    const updateFileData = filelist => {
        const filtered = filterFiles(
            [...filelist].map(f=>({
                file:f,
                filename:f.name,
                ext: f.name.split(".").pop()
            }))
        );
        if (filtered.length !== filelist.length) dispatch(setErrors(["Some files could not be uploaded, because the file type is restricted."]));
        dispatch(setFileUploadData(filtered));
    };
    const handleFileChange = event => {
        updateFileData(event.target.files);
        event.target.value = null;
    };
    if (authFailed) return;
    return (    
        <Fragment>
            <LoginBoxPage>
                <LoginBoxStyles height="400" width="600">
                <SectionTitle icon="fa fa-upload" size="small">Upload Project Files</SectionTitle>
                <Form onSubmit={handleSubmit}>
                    <RowInner>
                        <label>Project Number<br /><output>{projectData.projectNumber}</output></label>
                    </RowInner>
                    { projectData.projectName &&
                    <RowInner>
                        <label>Project Name<br /><output>{projectData.projectName}</output></label>
                    </RowInner>
                    }
                    <RowInner>
                        <ColHalf>
                          <label>Select Files<br />
                            <InputFile name="upload" onChange={handleFileChange} />  
                          </label>
                        </ColHalf>
                        <ColHalf>
                          {
                            hasFileList && <InputFileList tableData={uploadFileDataTable} />
                          }
                        </ColHalf>
                    </RowInner>
                    <RowInner>
                        <FormButton type="submit">
                            <Icon icon="fa fa-sign-in" /><ButtonText text="Upload Files" />
                        </FormButton>
                        </RowInner>
                </Form>
                </LoginBoxStyles>
            </LoginBoxPage>
            <Loading isVisible={userIsLoading} />
            <Progress fileData={uploadFileData.map(ele=>ele.filename)} progressData={uploadPropertiesRef.current} />
        </Fragment>
    );

};
export default FileUpload;