import styled from "styled-components";

export const ContainerStyles = styled.div`
    color:${(props)=>props.color ? props.color : "#000"};
    background-color:${(props)=> props.backgroundColor ? props.backgroundColor : "#FFF"};
    box-shadow:${(props) => (props.shadowType === "1") ? "0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%)" : "0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%)"};
    margin-bottom:${(props) => props.bmargin ? `${props.bmargin}px` : "0"};
    position:relative;
    padding:0.01em 16px;

    &::before, &::after {
        content: "";
        display: table;
        clear:both;
    }
`;