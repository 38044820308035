import styled from "styled-components";

export const ModalStyles = styled.div`
    position:fixed;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    z-index:6;
    background:#FFF;
    box-shadow:0 4px 10px 0 rgb(0 0 0 / 20%), 0 4px 20px 0 rgb(0 0 0 / 19%);
    width:${(props)=>props.width ? `${props.width}px` : "30%"};
    height:${(props)=>props.height ? `${props.height}px` : "auto"};
    display:${(props)=>props.isVisible ? "block" : "none"};
    padding:0.01em 16px;
`;