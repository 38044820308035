import { FILE_LIST_MODAL_ACTION_TYPES } from "./file-list-modal.types";

const INITIAL_STATE = {
  currentFileList: {
    header: ["Filename", ""],
    widths: ["80%,20%"],
    rows: [],
  },
};
export const fileListModalReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case FILE_LIST_MODAL_ACTION_TYPES.SET_CURRENT_FILE_LIST:
      return {
        ...state,
        currentFileList: payload,
      };
    default:
      return state;
  }
};
