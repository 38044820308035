import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Row from "../../components/row/row.component";
import RegistrationPanel from "../../components/registration-panel/registration-panel.component";
import { fetchUserRegistrationTokenAsync, setHasUpdated } from "../../store/user/user.actions";
import { getToken } from "../../store/user/user.selector";
import { selectHasUpdated } from "../../store/user/user.selector";


const Registration = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(getToken);
    const hasUpdated = useSelector(selectHasUpdated);
    const {id} = useParams();
    
    useEffect(()=>{
        if (token == null) dispatch(fetchUserRegistrationTokenAsync({client:id}));
    },[id, token, dispatch]);
    
    useEffect(()=>{
        if (!hasUpdated) return;
        dispatch(setHasUpdated(false));
        navigate("/");
        
    },[hasUpdated,navigate,dispatch]);

    return (
        <Row>
            <RegistrationPanel />
        </Row>
    );
};
export default Registration;