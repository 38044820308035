import styled from "styled-components";

export const HeaderInnerContainer = styled.div`
    background-color:#2196F3;
    width:45%;
    position:absolute;
    top:0;
    bottom:0;
    right:0;
    z-index:2;

    &::before {
        content:'';
        position:absolute;
        left:-140px;
        border-right:140px solid #2196F3;
        border-top:140px solid transparent;
        border-bottom:140px solid transparent;
    }
`;