import styled from "styled-components";
import Button from "../button/button.component";

export const LogoutButtonStyles = styled(Button)`
    background:#999;
    border-radius:5px;
    border:1px solid #CCC;
    cursor:pointer;
    position:absolute;
    top:20px;
    right:20px;
    color:#FFF;
`;