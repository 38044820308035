import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getToken } from "../../store/user/user.selector";
import { fetchFileDownloadUrlAsync, resetDownloadFileUrl } from "../../store/project-list/project-list.actions";
import { selectProjectDetails } from "../../store/project-list/project-list.selector";
import { FileDownloadIconContainer } from "./file-download-icon.styles";
import { triggerDownload } from "../../utils/download/download.utils";
import { selectDownloadFileUrl } from "../../store/project-list/project-list.selector";

const FileDownloadIcon = ({encid,downloadType="delivered"}) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const downloadFileUrl = useSelector(selectDownloadFileUrl);
    const projectDetails = useSelector(selectProjectDetails);

    useEffect(()=> {
        if (downloadFileUrl === "") return;
        triggerDownload(downloadFileUrl);
        dispatch(resetDownloadFileUrl());
    },[downloadFileUrl,dispatch]);

    const handleFileDownload = () => {
        const type = downloadType === "delivered" ? "job-delivered-files" : "job-source-files"
        const data = {
            file:encid,
            slug:projectDetails.slug,
            job:projectDetails.details.jobNumber,
            type
       }
       
       dispatch(fetchFileDownloadUrlAsync({data,token}));
    }

    return (
        <FileDownloadIconContainer icon="fa fa-download" className="fa fa-download" color="#2196F3" onClick={handleFileDownload} />
    );
};
export default FileDownloadIcon;