import styled, {keyframes} from "styled-components";

const rotateAnimation = keyframes`
    0% { transform: rotate(0);}
    100% { transform: rotate(360deg);}
`;
const rotateReverseAnimation = keyframes`
    0% { transform: rotate(0);}
    100% { transform: rotate(-360deg);}
`;

export const LoadingContainer = styled.div`
    z-index:6;
    width:80px;
    height:80px;
    display:flex;
    justify-content:center;
    align-items:center;
    font-size:1.4rem;
    overflow:hidden;
    position:fixed;
    border-radius:50%;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    display:${(props)=>props.isVisible ? "block" : "none"};

    &:before,&:after {
        content:"";
        animation-iteration-count: infinite;
        position:absolute;
        width:100%;
        height:100%;
        border-radius:50%;
        border:10px solid transparent;
        mix-blend-mode:overlay;
        pointer-events:none;
    }
    &:before {
        border-top-color:#2196F3;
        animation-name: ${rotateAnimation};
        animation-duration: 1.5s;
        animation-timing-fuction:ease-in-out;
    }
    &:after {
        border-bottom-color:#FFFFFF;
        animation-name: ${rotateReverseAnimation};
        animation-duration: 1.1s;
        animation-timing-function: linear;
    }
`;