import styled from "styled-components";

export const MessagesContainer = styled.div`
    position:relative;
    width:100%;
`;
export const ErrorP = styled.p`
    font-weight:bold;
    color:red;
    border:1px solid red;
    border-radius:5px;
    padding:6px;
`;
export const MessageP = styled.p`
    color:#757575;
`;