import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import Row from "../row/row.component";
import Panel from "../panel/panel.component";
import ModalClose from "../modal-close/modal-close.component";
import { MessagesContainer } from "./messages.styles";
import { selectMessages, selectErrors } from "../../store/messages/messages.selector";
import { clearAll, setErrors } from "../../store/messages/messages.actions";
import { ErrorP, MessageP } from "./messages.styles";
import { selectAIPPanels } from "../../store/aip/aip.selector";

import { selectUserError } from "../../store/user/user.selector";
import { selectServiceOptionsError } from "../../store/service-options/service-options.selector";
import { selectAllProjectListErrors } from "../../store/project-list/project-list.selector";

import { clearServiceOptionsError } from "../../store/service-options/service-options.actions";
import { clearUserError } from "../../store/user/user.actions";
import { clearAllProjectListErrors } from "../../store/project-list/project-list.actions";

const Messages = () => {
    const dispatch = useDispatch();
    const messages = useSelector(selectMessages);
    const errors = useSelector(selectErrors);
    const panels = useSelector(selectAIPPanels);
    
    const userError = useSelector(selectUserError);
    const serviceOptionsError = useSelector(selectServiceOptionsError);
    const projectListErrors = useSelector(selectAllProjectListErrors);
    
    useEffect(()=>{
        const hasErrors = () => {
            for (const prop in projectListErrors) {
                if (projectListErrors[prop]) return true;
            }
            return userError || serviceOptionsError;
        };
        const getPLErrors = () => {
            const msgs = [];
            for (const prop in projectListErrors) {
                if (projectListErrors[prop]) msgs.push(projectListErrors[prop].message);
            }
            return msgs;
        }
        const getErrors = () => {
            const msgs = [];
            if (userError) msgs.push(userError.message);
            if (serviceOptionsError) msgs.push(serviceOptionsError.message);
            const plerrors = getPLErrors();
            if (plerrors.length) msgs.push(...plerrors);
            return msgs;
        };
        if (!hasErrors()) return;
        dispatch(setErrors(getErrors()));
        dispatch(clearServiceOptionsError());
        dispatch(clearUserError());
        dispatch(clearAllProjectListErrors());
    },[dispatch,projectListErrors,serviceOptionsError,userError]);

    const ct = messages.length + errors.length;




    if (!ct) return;
      
    const handleDismiss = () => {
        dispatch(clearAll());
    };
    return (
        <Row>
            <Row>
              <MessagesContainer>
                <ModalClose onClick={handleDismiss} />
                <Panel {...panels[4]}>
                    {errors.length ? (errors.map((err,idx)=><ErrorP key={idx}>{err}</ErrorP>)) : ""}
                    {messages.length ? (messages.map((mes,idx)=><MessageP key={idx}>{mes}</MessageP>)) : ""}
                </Panel>
              </MessagesContainer>
            </Row>
        </Row>
    );
};
export default Messages;