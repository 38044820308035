import styled from "styled-components";

export const LoginBoxPage = styled.div`
    position:relative;
    width:100%;
    min-height:760px;
    background:#FFF;
`;
export const LoginBoxStyles = styled.div`
    width:${(props)=>props?.width ? `${props.width}px` : "360px"};
    height:${(props)=>props?.height ? `${props.height}px` : "240px"};
    background:#FFF;
    box-shadow:0 4px 10px 0 rgb(0 0 0 / 20%),0 4px 20px 0 rgb(0 0 0 / 19%);
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    padding:0 10px 10px 10px;
`;
export const LoginBoxRow = styled.div`
    width:100%;
    text-align:center;
    margin-bottom:20px;
`;