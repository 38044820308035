import { MESSAGES_TYPES } from "./messages.types";

const INITIAL_STATE = {
  messages: [],
  errors: [],
};

export const messagesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case MESSAGES_TYPES.ADD_ERROR:
      return {
        ...state,
        errors: [...state.errors, payload],
      };
    case MESSAGES_TYPES.SET_MESSAGE:
      return {
        ...state,
        messages: payload,
      };
    case MESSAGES_TYPES.SET_ERROR:
      return {
        ...state,
        errors: payload,
      };
    case MESSAGES_TYPES.CLEAR_MESSAGES:
      return {
        ...state,
        messages: [],
      };
    case MESSAGES_TYPES.CLEAR_ERRORS:
      return {
        ...state,
        errors: [],
      };
    case MESSAGES_TYPES.CLEAR_ALL:
      return {
        ...state,
        messages: [],
        errors: [],
      };
    default:
      return state;
  }
};
