import { useSelector, useDispatch } from "react-redux";
import { OpenerStyles } from "./opener.styles";

import { selectAIPProps } from "../../store/aip/aip.selector";
import { setAIPProps } from "../../store/aip/aip.actions";


const Opener = ({openerID}) => {
    const dispatch = useDispatch();
    const AIPProps = useSelector(selectAIPProps);

    if (AIPProps == null) return "";
    const {openers, colThird, colTwoThird} = AIPProps;

    const opener = openers.filter((ele=>ele.id === parseInt(openerID)))[0];

    const updateProperties = () => {
        const newColThird = {isOpen:colThird.isOpen === 1 ? 0 : 1};
        const newColTwoThird = {isOpen:colTwoThird.isOpen === 1 ? 0 : 1};
        const newOpeners = openers.map(ele=>(
            {
                ...ele,
                isVisible:ele.isVisible === 1 ? 0 : 1
            }
        ));
        const newProps = {...AIPProps,openers:newOpeners,colThird:newColThird,colTwoThird:newColTwoThird};
        dispatch(setAIPProps(newProps));
    };

    const clickHandler = () => {
        updateProperties();
    }
    return <OpenerStyles position={opener.position} isVisible={opener.isVisible} onClick={clickHandler}></OpenerStyles>;
    
};
export default Opener;