import styled from "styled-components";

export const HRStyles = styled.hr`
    border:0;
    border-top:1px solid #EEE;
    margin:20px 0;
    box-sizing:content-box;
    height:0;
    overflow:visible;

`;