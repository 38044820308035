import axios from "axios";
//imports for spoofs
import tableData from "../../data/table.data.json";
import tempDetails from "../../data/details.data.json";

export const AWSRequestLive = (data, endpoint, token) => {
  return new Promise((resolve, reject) => {
    fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json())
      .then(res => resolve(res))
      .catch(error => reject(error));
  });
};
export const AWSRequestMulti = (data, endpoint, token) => {
  return new Promise((resolve, reject) => {
    const calls = [];
    data.forEach(cd => {
      calls.push(
        fetch(endpoint, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(cd),
        }).then(response => response.json())
      );
    });
    Promise.all(calls)
      .then(vals => resolve(vals))
      .catch(error => reject(error));
  });
};
export const AWSRequestMultiFileUpload = calldata => {
  return new Promise((resolve, reject) => {
    const calls = [];
    calldata.forEach(call => {
      calls.push(
        axios.put(call.endpoint, call.data, {
          headers: {
            "Content-Type": "binary/octet-stream",
          },
          onUploadProgress: call.updater,
        })
      );
    });
    Promise.all(calls)
      .then(vals => resolve(vals))
      .catch(error => reject(error));
  });
};
export const AWSRequestSp = (data, endpoint, token) => {
  return new Promise((resolve, reject) => {
    switch (endpoint) {
      default:
        return reject("An unmapped endpoint has been called.");
      case "https://clients-endpoint.worldtranslationcenter.com/v1/login":
        return resolve({
          statusCode: 200,
          token: "testtoken",
        });
      case "reject:https://clients-endpoint.worldtranslationcenter.com/v1/login":
        return resolve({
          statusCode: 401,
          errorMsg: "The username or password was incorrect.",
        });
      case "https://clients-endpoint.worldtranslationcenter.com/v1/new-job-lists":
        return resolve({
          statusCode: 200,
          languages: [],
          services: [],
          formats: [],
        });
      case "https://clients-endpoint.worldtranslationcenter.com/v1/list-jobs":
        return setTimeout(() => {
          resolve({
            pages: [
              {
                jobID: "encodedid",
                jobNumber: "12345-3-1",
                jobName: "Some Project Title",
                jobStart: 165725800,
                status: "Processing",
              },
              {
                jobID: "encodedid",
                jobNumber: "51516-1-1",
                jobName: "Another project title",
                jobStart: 165725800,
                status: "Complete",
              },
              {
                jobID: "encodedid",
                jobNumber: "65155-1-1",
                jobName: "This project title.",
                jobStart: 165725800,
                status: "Processing",
              },
            ],
            pageCount: 1,
            statusCode: 200,
          });
        }, 3000);
      case "https://clients-endpoint.worldtranslationcenter.com/v1/list-jobs_TEST":
        if (data?.orderField) {
          return setTimeout(() => {
            resolve({
              pages: [
                [
                  {
                    jobID: "encodedid",
                    jobNumber: "12345-3-1",
                    jobName: "Some Project Title",
                    jobStart: 165725800,
                    jobDead: 165725800,
                    status: "Processing",
                  },
                  {
                    jobID: "encodedid",
                    jobNumber: "51516-1-1",
                    jobName: "Another project title",
                    jobStart: 165725800,
                    jobDead: 165725800,
                    status: "Complete",
                  },
                  {
                    jobID: "encodedid",
                    jobNumber: "65155-1-1",
                    jobName: "This project title.",
                    jobStart: 165725800,
                    jobDead: 165725800,
                    status: "Processing",
                  },
                ],
              ],
              pageCount: 12,
              statusCode: 200,
            });
          }, 4000);
        } else {
          const pages = tableData.rows.map(ele => ({
            jobID: "encodedid",
            jobNumber: ele[0],
            jobName: ele[1],
            status: ele[2],
            jobStart: 1658182960,
            jobDead: 165725800,
          }));
          return setTimeout(() => {
            const outer = [pages];
            resolve({
              pages: outer,
              pageCount: 12,
              statusCode: 200,
            });
          }, 5000);
        }
      case "https://clients-endpoint.worldtranslationcenter.com/v1/view-job":
        //return resolve({ statusCode: 500 });
        return setTimeout(() => {
          resolve({ statusCode: 200, data: tempDetails });
        }, 5000);
    }
  });
};

export const AWSRequest = (data, endpoint, token) =>
  AWSRequestLive(data, endpoint, token);
