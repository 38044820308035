import { createSelector } from "reselect";
export const selectServiceOptionsReducer = state => state.so;

export const selectServiceOptions = createSelector(
  [selectServiceOptionsReducer],
  slice => slice.options
);
export const selectLanguages = createSelector(
  [selectServiceOptionsReducer],
  slice => slice.options.languages
);
export const selectFormats = createSelector(
  [selectServiceOptionsReducer],
  slice => slice.options.formats
);
export const selectServices = createSelector(
  [selectServiceOptionsReducer],
  slice => slice.options.services
);
export const selectServiceOptionsError = createSelector(
  [selectServiceOptionsReducer],
  slice => slice.error
);
