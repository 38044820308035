import { useSelector } from "react-redux";
import { ColThirdStyles } from "./col-third.styles";
import { selectAIPProps } from "../../store/aip/aip.selector";


const ColThird = ({children}) => {
    const AIPProps = useSelector(selectAIPProps);
    if (AIPProps == null) return;
    const {colThird:{isOpen}} = AIPProps;
    return (
        <ColThirdStyles isOpen={isOpen}>{children}</ColThirdStyles>
    );
};
export default ColThird;