import styled from "styled-components";

export const IconStyles = styled.i`
    width:1.28571em;
    text-align:center;
    display:inline-block;
    font-size:${(props)=>props.size ? `${props.size}px` : "inherit"};
    text-rendering:auto;
    -webkit-font-smoothing:antialiased;
    color:${(props)=>props.color ? props.color : "inherit"}
`;