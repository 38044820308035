export const UPLOAD_ONLY_ACTION_TYPES = {
  START_AUTH: "uploadonly/START_AUTH",
  AUTH_SUCCESS: "uploadonly/AUTH_SUCCES",
  AUTH_FAILED: "uploadonly/AUTH_FAILED",
  AUTH_SET_TOKEN: "uploadonly/AUTH_SET_TOKEN",
  SET_PROJECT_DATA: "uploadonly/SET_PROJECT_DATA",
  SET_FILE_DATA: "uploadonly/SET_FILE_DATA",
  PROCESS_UPLOADS_START: "uploadonly/PROCESS_UPLOADS_START",
  PROCESS_UPLOADS_SUCCESS: "uploadonly/PROCESS_UPLOADS_SUCCESS",
  PROCESS_UPLOADS_FAILED: "uploadonly/PROCESS_UPLOADS_FAILED",
  CLEAR_PROCESS_COMPLETE: "uploadonly/CLEAR_PROCESS_COMPLETE",
  RESET_STATE: "uploadonly/RESET_STATE",
};
