import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RowInner from "../row-inner/row-inner.component";
import Form from "../form/form.component";
import InputSelect from "../input-select/input-select.component";
import ColHalf from "../col-half/col-half.component";
import Input from "../input/input.component";
import FormButton from "../form-button/form-button.component";
import Icon from "../icon/icon.component";
import ButtonText from "../button-text/button-text.component";
import { timestampFromInputDate } from "../../utils/dates/date.utils";
import { fetchProjectListAsync, setQueryOptions, resetQueryOptions } from "../../store/project-list/project-list.actions";
import { selectQueryOptions } from "../../store/project-list/project-list.selector";
import { getToken } from "../../store/user/user.selector";

const statusOptionsDefault = [
    {
        value:0,
        label:"All Projects"
    },
    {
        value:8,
        label:"New"
    },
    {
        value:9,
        label:"Processing"
    },
    {
        value:10,
        label:"Invoiced"
    },
    {
        value:11,
        label:"Completed"
    }
];
const sortOptionsDefault = [
    {
        value:"jobID",
        label:"Chronological"
    },
    {
        value:"jobNumber",
        label:"Job Number"
    },
    {
        value:"status",
        label:"Status"
    },
    {
        value:"jobStart",
        label:"Start Date"
    },
    {
        value:"jobDead",
        label:"Deadline"
    }
];

/*
const today = getDate(new Date());
const defaultDatePair = formatInputDatePair(today,{days:30});
const { startDate, endDate } = defaultDatePair;
*/

const defaultValues = {
    status:0,
    keyword:"",
    number:"",
    dateStartFrom:"",
    dateStartTo:"",
    dateDeadlineFrom:"",
    dateDeadlineTo:"",
    sort:"jobID",
    direction:"DESC"
};
const SearchForm = () => {
    const dispatch = useDispatch();
    const [formFields, setFormFields] = useState(defaultValues);
    const token = useSelector(getToken);
    const queryOptions = useSelector(selectQueryOptions);

    useEffect(()=>{
        if (token == null) return;
        dispatch(fetchProjectListAsync({data:queryOptions,token}));
    },[queryOptions,dispatch,token]);


    const {status,keyword,number,dateStartFrom,dateStartTo,dateDeadlineFrom,dateDeadlineTo,sort,direction} = formFields;
    const resetFormFields = () => {
        setFormFields(defaultValues);
    };
    const generateSearchResults = (status,keyword,number,dateStartFrom,dateStartTo,dateDeadlineFrom,dateDeadlineTo,sort,direction) => {
        const data = {
            orderField:sort,
            orderSort:direction,
            limitStart:0,
            limitCount: queryOptions?.limitCount ? queryOptions.limitCount : 15
        };
        if (status) data.statusID = parseInt(status);
        if (keyword.length) data.keywords = keyword;
        if (number.length) data.jobNumber = number.split('-')[0];
        if (dateStartFrom.length) data.startFrom = timestampFromInputDate(dateStartFrom);
        if (dateStartTo.length) data.startTo = timestampFromInputDate(dateStartTo);
        if (dateDeadlineFrom.length) data.deadFrom = timestampFromInputDate(dateDeadlineFrom);
        if (dateDeadlineTo.length) data.deadTo = timestampFromInputDate(dateDeadlineTo);

        dispatch(setQueryOptions(data));
        dispatch(fetchProjectListAsync({data,token}));
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        generateSearchResults(status,keyword,number,dateStartFrom,dateStartTo,dateDeadlineFrom,dateDeadlineTo,sort,direction);
    };
    const handleChange = (event) =>{
        const { name, value } = event.target;
        setFormFields({...formFields, [name]: value});
    };
    const handleReset = () => {
        resetFormFields();
        dispatch(resetQueryOptions());
    }
    return (
        <Form onSubmit={handleSubmit}>
            <RowInner>
                <label>Status<br />
                    <InputSelect onChange={handleChange} currentValue={status} name="status" options={statusOptionsDefault}></InputSelect>
                </label>
            </RowInner>
            <RowInner>
                <ColHalf>
                    <label>Keywords<br /> 
                        <Input onChange={handleChange} type="text" name="keyword" value={keyword} width="360" placeholder="Ex: E-learning" />
                    </label>
                </ColHalf>
                <ColHalf>
                    <label>Project Number<br /> 
                        <Input onChange={handleChange} type="text" name="number" value={number} width="360" placeholder="Ex: 10501-1-1" />
                    </label>
                </ColHalf>
            </RowInner>
            <RowInner>
                <ColHalf>
                    <label>Start Date<br />
                        <Input onChange={handleChange} type="date" name="dateStartFrom" holder="From" value={dateStartFrom} width="178" /> 
                        <Input onChange={handleChange} type="date" name="dateStartTo" holder="To" value={dateStartTo} width="178" />
                    </label>
                </ColHalf>
                <ColHalf>
                    <label>Deadline<br />
                        <Input onChange={handleChange} type="date" name="dateDeadlineFrom" holder="From" value={dateDeadlineFrom} width="178" /> 
                        <Input onChange={handleChange} type="date" name="dateDeadlineTo" holder="To" value={dateDeadlineTo} width="178" />
                    </label>
                </ColHalf>
            </RowInner>
            <RowInner>
                <ColHalf>
                    <label>Sort Field<br />
                        <InputSelect onChange={handleChange} name="sort" currentValue={sort} options={sortOptionsDefault} width="178" />
                    </label>
                </ColHalf>
                <ColHalf>
                    <RowInner>
                    <label>Sort Direction<br />
                        <ColHalf><Input onChange={handleChange} name="direction" type="radio" width="20" value="DESC" checked/> Descending</ColHalf>
                        <ColHalf><Input onChange={handleChange} name="direction" type="radio" width="20" value="ASC" /> Ascending</ColHalf>
                    </label>
                    </RowInner>
                </ColHalf>
            </RowInner>
            <RowInner>
                <ColHalf>
                    <FormButton type="submit">
                        <Icon icon="fa fa-search" color="#FFF" /><ButtonText text="Find Projects" color="#FFF" />
                    </FormButton>
                </ColHalf>
                <ColHalf>
                    <FormButton type="button" onClick={handleReset}>
                        <Icon icon="fa fa-refresh" color="#FFF" /><ButtonText text="Reset" color="#FFF" />
                    </FormButton>
                </ColHalf>
            </RowInner>
        </Form>
    );
};
export default SearchForm;