import { createSelector } from "reselect";

export const selectUploadOnlyReducer = state => state.upo;

export const selectToken = createSelector(
  [selectUploadOnlyReducer],
  slice => slice.token
);
export const selectProjectData = createSelector(
  [selectUploadOnlyReducer],
  slice => slice.projectData
);
export const selectAuthError = createSelector(
  [selectUploadOnlyReducer],
  slice => slice.authError
);
export const selectFlags = createSelector(
  [selectUploadOnlyReducer],
  slice => slice.flags
);
export const selectUploadFileData = createSelector(
  [selectUploadOnlyReducer],
  slice => slice.uploadFileData
);
export const selectUploadFileDataTable = createSelector(
  [selectUploadOnlyReducer],
  slice => ({
    header: ["Filename", ""],
    widths: ["80%,20%"],
    rows: slice.uploadFileData.map(ele => [ele.filename, ""]),
  })
);
export const selectHasUploadFileData = createSelector(
  [selectUploadOnlyReducer],
  slice => (slice.uploadFileData.length > 0 ? true : false)
);
export const selectIsLoading = createSelector(
  [selectUploadOnlyReducer],
  slice =>
    slice.flags.authInit ||
    slice.flags.authStarted ||
    slice.flags.processStarted
);
