import styled from "styled-components";

export const TextareaStyles = styled.textarea`
    width:75%;
    width:${(props)=>props.width ? props.width : "75%"};
    height:${(props)=>props.height ? `${props.height}px` : "200px"};
    padding:4px;
    border-style:solid;
    border-width:1px;
    border-color:#CCC;
    border-radius:5px;
`;