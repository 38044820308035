import styled, {css} from "styled-components";

const OpenerLeft = css`
    right:0;
    border-radius: 10px 0 0 10px;
    &:hover {
        width:30px;
        &:before {
            content: '\\00BB';
            position:absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-55%);
            font-size:1.8em;
        }
    }

`;
const OpenerRight = css`
    left:0;
    border-radius: 0 10px 10px 0;
    &:hover {
        width:30px;
        &:before {
            content:'\\00AB';
            position:absolute;
            top:50%;
            left:50%;
            transform: translate(-50%,-55%);
            font-size:1.8em;
        }
    }
    
`;
const None = css`
    display:none;
`;
export const OpenerStyles = styled.div`
    width: 8px;
    height: 40px;
    position:absolute;
    top:50%;
    transform:translate(0,-50%);
    background:#999;
    transition: all 300ms;
    cursor:pointer;
    z-index:3;
    ${({position})=>(position === 1) ? OpenerLeft : OpenerRight}
    ${({isVisible}) => !parseInt(isVisible) && None}
`;