import { Fragment } from "react";
import { useSelector } from "react-redux";
import { Element } from "react-scroll";
import { selectDetailsIsLoading, selectProjectDetails } from "../../store/project-list/project-list.selector";
import Spinner from "../spinner/spinner.component";
import ProjectDetailsPanel from "../project-details-panel/project-details-panel.component";

const ProjectDetails = () => {
    const projectDetails = useSelector(selectProjectDetails);
    const isLoading = useSelector(selectDetailsIsLoading);
    return (
        <Fragment>
            <Element name="scrollProjectDetails"></Element>
        {isLoading ? (<Spinner />) : (<ProjectDetailsPanel projectDetails={projectDetails} />)}
        </Fragment>
    );
};
export default ProjectDetails;