import styled from "styled-components";

export const CoverStyles = styled.div`
    position:fixed;
    z-index:5;
    background:#000;
    opacity:0.2;
    top:0;
    left:0;
    bottom:0;
    right:0;
    display:${(props)=>props.isVisible ? "block" : "none"};
`;