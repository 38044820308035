import styled from "styled-components";

export const DetailsDataElementLabel = styled.div`
    color:#000;
    opacity:0.6;
    font-size:0.7em;
    margin-bottom:-5px;
    width:100%;
`;
export const DetailsDataElementData = styled.div`
    width:100%;
    padding:0 0 0 20px;
    font-size:1.1em;
    color:#757575;
`;