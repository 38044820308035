import { createSelector } from "reselect";

export const selectCurrentUserReducer = state => state.user;

//export const selectCurrentUser = state => state.user.currentUser;

export const selectCurrentUser = createSelector(
  [selectCurrentUserReducer],
  slice => slice.currentUser
);
export const getToken = createSelector(
  [selectCurrentUserReducer],
  slice => slice?.currentUser?.token
);
export const selectUserError = createSelector(
  [selectCurrentUserReducer],
  slice => slice.error
);
export const selectUserIsLoading = createSelector(
  [selectCurrentUserReducer],
  slice => slice.isLoading
);
export const selectUserTokenExpires = createSelector(
  [selectCurrentUserReducer],
  slice => slice?.currentUser?.expires
);
export const selectHasUpdated = createSelector(
  [selectCurrentUserReducer],
  slice => slice.hasUpdated
);
export const selectEncLogin = createSelector(
  [selectCurrentUserReducer],
  slice => slice.encLogin
);
export const selectCheckEncoded = createSelector(
  [selectCurrentUserReducer],
  slice => slice.checkEncoded
);
export const selectEnc = createSelector(
  [selectCurrentUserReducer],
  slice => slice.enc
);
