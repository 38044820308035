import styled from "styled-components";

export const InputStyles = styled.input`
    width:${({width})=>`${width}px`};
    overflow:visible;
    font:inherit;
    margin:0;
    border-color:#CCC;
    border-width:1px;
    border-radius:5px;
    border-style:solid;
    padding:4px;
    position:relative;
    ${({holder})=>holder && holder !== "" ? `&:after {content:"${holder}";font-size:0.7em;position:absolute;top:-16px;right:4px;color:#757575;}` : ""}
`;