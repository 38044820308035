import TableRowElement from "../table-row-element/table-row-element.component";
import { TableRowStyles } from "./table-row.styles";

const TableRow = ({row}) => { 
    return (
        <TableRowStyles>
            {row.map((val,idx)=><TableRowElement key={`tablerowelement${idx}`} value={val} />)}
        </TableRowStyles>
    );
}
export default TableRow;