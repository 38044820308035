import { useSelector, useDispatch } from "react-redux";
import { SelectPageContainer, SelectPageOption } from "./select-page.styles";
import { selectPageCount, selectQueryOptions, selectQueryOptionsLimitCount } from "../../store/project-list/project-list.selector";
import { fetchProjectListAsync, setQueryOptionsLimitStart, setQueryOptionsPageNum } from "../../store/project-list/project-list.actions";
import { getToken } from "../../store/user/user.selector";

const SelectPage = () => {
    const dispatch = useDispatch();
    const pageCount = useSelector(selectPageCount);
    const queryOptions = useSelector(selectQueryOptions);
    const limitCount = useSelector(selectQueryOptionsLimitCount);
    const token = useSelector(getToken);
    const pageNum = queryOptions?.pageNum ? queryOptions.pageNum : 1;
    if (pageCount < 2) return;
    const pageNumbers = [...Array(pageCount+1).keys()];
    pageNumbers.shift();
    
    const calculateLimitStart = (pageNum, count) => {
        return (pageNum - 1) * count;
    };
    const handlePageChange = (event) => {
        const pageNum = parseInt(event.target.value);
        dispatch(setQueryOptionsPageNum(pageNum));
        dispatch(setQueryOptionsLimitStart(calculateLimitStart(pageNum,limitCount)));
        dispatch(fetchProjectListAsync({data:{...queryOptions},token}));
    }

    return (
        <SelectPageContainer onChange={handlePageChange} value={pageNum}>
            {
                pageNumbers.map(num=><SelectPageOption value={num} key={num}>{num}</SelectPageOption>)
            }
        </SelectPageContainer>
    );
};
export default SelectPage;