import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableRowStyles } from "../table-row/table-row.styles";
import TableRowElement from "../table-row-element/table-row-element.component";
import { fetchProjectDetailsAsync } from "../../store/project-list/project-list.actions";
import { setErrors } from "../../store/messages/messages.actions";
import { selectErrors } from "../../store/messages/messages.selector";
import { selectDetailsError } from "../../store/project-list/project-list.selector";
import { scroller } from "react-scroll";
import { getToken } from "../../store/user/user.selector";

const TableClickRow = ({row,encid}) => {
    const dispatch = useDispatch();
    const token = useSelector(getToken);
    const errors = useSelector(selectErrors);
    const detailsError = useSelector(selectDetailsError);
    const detailsErrorRef = useRef(detailsError);
    const handleClick = (event) => {
        const parent = event.target.closest('tr');
        dispatch(fetchProjectDetailsAsync({data:{jobID:parent.dataset.encid}, token}));
        if (detailsErrorRef?.current?.message) {
            dispatch(setErrors([...errors,detailsError.message]));
        }
        scroller.scrollTo('scrollProjectDetails',{
            duration:1200,
            delay:200,
            smooth:true,
            offset:50
        });
    };
    return (
        <TableRowStyles data-encid={encid} onClick={handleClick}>
            {row.map((val,idx)=><TableRowElement key={`tablerowelement${idx}`} value={val} />)}
        </TableRowStyles>
    );
};
export default TableClickRow;