import styled from "styled-components";

export const LargeTitleWrapper = styled.h2`
    color:#757575;
    padding-top:16px;
    padding-bottom:16px;
    font-weight:400;
    margin:10px 0;
    font-size:30px;
`;
export const SmallTitleWrapper = styled.p`
    color:#757575;
    font-size:18px;
    font-weight:bold;
`;