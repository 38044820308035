import { USER_ACTION_TYPES } from "./user.types";

const INITIAL_STATE = {
  currentUser: null,
  isLoading: false,
  error: null,
  hasUpdated: false,
  encLogin: false,
  checkEncoded: true,
  enc: null,
};

export const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTION_TYPES.SET_ENC:
      return {
        ...state,
        encLogin: true,
        enc: payload,
      };
    case USER_ACTION_TYPES.SET_CHECK_ENCODED:
      return {
        ...state,
        checkEncoded: payload,
      };
    case USER_ACTION_TYPES.SET_ENC_LOGIN:
      return {
        ...state,
        encLogin: payload,
      };
    case USER_ACTION_TYPES.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload,
      };
    case USER_ACTION_TYPES.FETCH_LOGIN_START:
    case USER_ACTION_TYPES.FETCH_USER_REGISTRATION_START:
    case USER_ACTION_TYPES.FETCH_PASSWORD_UPDATE_START:
      return {
        ...state,
        error: null,
        isLoading: true,
      };
    case USER_ACTION_TYPES.FETCH_LOGIN_SUCCESS:
    case USER_ACTION_TYPES.FETCH_USER_REGISTRATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentUser: payload,
      };
    case USER_ACTION_TYPES.FETCH_PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasUpdated: true,
        currentUser: { ...state.currentUser, updatePassword: 1 },
      };
    case USER_ACTION_TYPES.FETCH_LOGIN_FAILED:
    case USER_ACTION_TYPES.FETCH_USER_REGISTRATION_FAILED:
    case USER_ACTION_TYPES.FETCH_PASSWORD_UPDATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case USER_ACTION_TYPES.CLEAR_ERROR:
      return {
        ...state,
        error: null,
      };
    case USER_ACTION_TYPES.RESET_USER:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case USER_ACTION_TYPES.SET_HAS_UPDATED:
      return {
        ...state,
        hasUpdated: payload,
      };
    default:
      return state;
  }
};
