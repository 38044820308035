import { PROJECT_FORM_SERVICES_ACTION_TYPES } from "./project-form-services.types";

const INITIAL_STATE = {
  projectFormServices: {
    header: [],
    rows: [],
    widths: [],
    files: [],
    rawfiles: [],
    data: [],
  },
};

export const projectFormServicesReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case PROJECT_FORM_SERVICES_ACTION_TYPES.SET_PROJECT_FORM_SERVICES:
      return {
        ...state,
        projectFormServices: payload,
      };
    case PROJECT_FORM_SERVICES_ACTION_TYPES.RESET_SERVICES:
      return {
        ...state,
        projectFormServices: { ...INITIAL_STATE.projectFormServices },
      };
    default:
      return state;
  }
};
