import { createAction } from "../../utils/reducer/reducer.utils";
import { USER_ACTION_TYPES } from "./user.types";
import { AWSRequest } from "../../utils/AWS/aws.utils";
import { futureTimestamp } from "../../utils/dates/date.utils";

export const setCurrentUser = user =>
  createAction(USER_ACTION_TYPES.SET_CURRENT_USER, user);

export const clearUserError = () => createAction(USER_ACTION_TYPES.CLEAR_ERROR);

export const resetUser = () => createAction(USER_ACTION_TYPES.RESET_USER);

export const setEncLogin = isEnc =>
  createAction(USER_ACTION_TYPES.SET_ENC_LOGIN, isEnc);

export const setCheckEncoded = doCheck =>
  createAction(USER_ACTION_TYPES.SET_CHECK_ENCODED, doCheck);

export const setEnc = enc => createAction(USER_ACTION_TYPES.SET_ENC, enc);

export const fetchLoginStart = () =>
  createAction(USER_ACTION_TYPES.FETCH_LOGIN_START);

export const fetchLoginSuccess = user =>
  createAction(USER_ACTION_TYPES.FETCH_LOGIN_SUCCESS, user);

export const fetchLoginFailed = error =>
  createAction(USER_ACTION_TYPES.FETCH_LOGIN_FAILED, error);

export const loginAsync =
  ({ email, password, enc }) =>
  async dispatch => {
    //params {email, password,enc}
    dispatch(fetchLoginStart());
    const data = {
      email,
      password,
    };
    if (enc) data.encdata = enc;
    const endpoint =
      "https://clients-endpoint.worldtranslationcenter.com/v1/login";
    try {
      let errorMsg = "";
      const response = await AWSRequest(data, endpoint, "notokenrequiredhere");
      switch (response.statusCode) {
        case 200:
          const expires = futureTimestamp({ hours: 24 });
          dispatch(
            fetchLoginSuccess({ email, token: response.token, expires })
          );
          break;
        case 401:
          /*  
        dispatch(
            fetchLoginFailed({
              message:
                "The email or password was incorrect. Please try to login again.",
              name: "NOTAUTHORIZED",
            })
          );
          */
          errorMsg =
            "The email or password was incorrect. Please try to login again.";
          break;
        case 500:
        default:
          /*  
        dispatch(
            fetchLoginFailed({
              message: "An error has occurred. Please try to login again.",
              name: "UNKNOWN",
            })
          );
          */
          errorMsg = "An error has occurred. Please try to login again.";
          break;
      }
      if (errorMsg) {
        dispatch(fetchLoginFailed({ message: errorMsg }));
      }
    } catch (error) {
      dispatch(
        fetchLoginFailed({
          message: "An error has occurred. Please try to login again.",
          name: "UNKNOWN",
        })
      );
    }
  };

export const fetchUserRegistrationStart = () =>
  createAction(USER_ACTION_TYPES.FETCH_USER_REGISTRATION_START);
export const fetchUserRegistrationFailed = error =>
  createAction(USER_ACTION_TYPES.FETCH_USER_REGISTRATION_FAILED, error);
export const fetchUserRegistrationSuccess = user =>
  createAction(USER_ACTION_TYPES.FETCH_USER_REGISTRATION_SUCCESS, user);

export const fetchUserRegistrationTokenAsync = data => async dispatch => {
  dispatch(fetchUserRegistrationStart());
  try {
    const response = await AWSRequest(
      data,
      "https://clients-endpoint.worldtranslationcenter.com/v1/verify-registration-token",
      "notokenrequired"
    );
    switch (response.statusCode) {
      case 200:
        dispatch(
          fetchUserRegistrationSuccess({
            token: response.token,
            expires: futureTimestamp({ hours: 24 }),
            email: response.email,
            updatePassword: response?.update === 1 ? 1 : 0,
          })
        );
        break;
      case 401:
        dispatch(
          fetchUserRegistrationFailed({
            message:
              "The registration token is expired or the email address did not match our records. Please contact your project manager for a new invitation link.",
            name: "REGISTRATIONTOKENEXPIRED",
          })
        );
        break;
      case 500:
      default:
        dispatch(
          fetchUserRegistrationFailed({
            message: "Failed to capture user details.",
            name: "USERREGISTRATIONFAILED",
          })
        );
        break;
    }
  } catch (error) {
    console.log(error);
    dispatch(
      fetchUserRegistrationFailed({
        message: "Failed to capture user details.",
        name: "USERREGISTRATIONFAILED",
      })
    );
  }
};
export const setHasUpdated = val =>
  createAction(USER_ACTION_TYPES.SET_HAS_UPDATED, val);
export const fetchPasswordUpdateFailed = error =>
  createAction(USER_ACTION_TYPES.FETCH_PASSWORD_UPDATE_FAILED, error);
export const fetchPasswordUpdateStart = () =>
  createAction(USER_ACTION_TYPES.FETCH_PASSWORD_UPDATE_START);
export const fetchPasswordUpdateSuccess = () =>
  createAction(USER_ACTION_TYPES.FETCH_PASSWORD_UPDATE_SUCCESS);

export const fetchPasswordUpdateAsync = params => async dispatch => {
  dispatch(fetchPasswordUpdateStart());
  try {
    const response = await AWSRequest(
      params.data,
      "https://clients-endpoint.worldtranslationcenter.com/v1/update-password",
      params.token
    );
    switch (response.statusCode) {
      case 200:
        dispatch(fetchPasswordUpdateSuccess());
        break;
      case 401:
        dispatch(
          fetchPasswordUpdateFailed({
            message: "Not authorized.",
            name: "AUTHORIZATIONERROR",
          })
        );
        break;
      case 500:
      default:
        dispatch(
          fetchPasswordUpdateFailed({
            message: "The password could not be update.",
            name: "UNKNOWNPASSWORDUPDATEFAILED",
          })
        );
        break;
    }
  } catch (error) {
    console.log(error);
    dispatch(
      fetchPasswordUpdateFailed({
        message: "The password could not be update.",
        name: "UNKNOWNPASSWORDUPDATEFAILED",
      })
    );
  }
};
