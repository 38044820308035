import { MODAL_ACTION_TYPES } from "./modal.types";

const INITIAL_STATE = {
  modalFields: { sourcelanguage: { isChecked: false, value: "1" } },
};

export const modalReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case MODAL_ACTION_TYPES.SET_MODAL_FIELDS:
      return {
        ...state,
        modalFields: payload,
      };
    default:
      return state;
  }
};
