import { useDispatch, useSelector } from "react-redux";

import { HeaderOuterContainer, HeaderPadding, HeaderStyles, HeaderContainer } from "./header.styles";
import Logo from '../logo/logo.component';
import LogoutButton from "../logout-button/logout-button.component";
import Icon from "../icon/icon.component";
import ButtonText from "../button-text/button-text.component";
import HeaderInner from "../header-inner/header-inner.component";
import logo from "../../assets/WTC-logo.png";

import { setCurrentUser } from "../../store/user/user.actions";
import { selectCurrentUser } from "../../store/user/user.selector";


const Header = () => {
    //const { currentUser, setCurrentUser } = useContext(UserContext);
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser);

    const logoutHandler = () => {
        dispatch(setCurrentUser({}));
    }
    return(
    <HeaderOuterContainer>
        <HeaderPadding>
            <HeaderStyles>
                <HeaderContainer>
                    <Logo src={logo} alt="Logo" />
                        <HeaderInner>
                        {currentUser?.token &&    
                            <LogoutButton type="button" onClick={logoutHandler}>
                                <Icon icon="fa fa-sign-out" color="#FFF" /><ButtonText text="Logout" color="#FFF" />
                            </LogoutButton>
                        }
                        </HeaderInner>
                </HeaderContainer>
            </HeaderStyles>
        </HeaderPadding>
    </HeaderOuterContainer>
    );
}
export default Header;