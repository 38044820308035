import styled from "styled-components";

export const InputFileListStyles = styled.div`
    width:100%;
    border:1px solid #CCC;
    border-radius:5px;
`;
export const InputFileListWrapper = styled.div`
    position:relative;
    z-index:4;
    width: 300px;
    height:150px;
    background:#FFF;
    padding:0 12px 0 12px;
    overflow-y:auto;
    margin-top:10px;
`;