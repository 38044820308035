import Modal from "../modal/modal.component";
import SectionTitle from "../section-title/section-title.component";
import RowInner from "../row-inner/row-inner.component";
import { ProgressBarStyles } from "./progress.styles";

const Progress = ({fileData,progressData,...otherProps}) => {
    return (
        <Modal modalId="uploadProgressModal" width="300" {...otherProps}>
            <SectionTitle icon="fa fa-upload" size="small">File Uploads</SectionTitle>
            {fileData.map((ele,idx)=>{
                return (
                    <RowInner key={idx}>
                        <label>{`${ele.slice(0,20)}...`}<br />
                        <ProgressBarStyles value={progressData[idx]} max="100"></ProgressBarStyles>
                        </label>
                    </RowInner>
                );
            })}
        </Modal>
    );
};
export default Progress;