import { Fragment } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../../components/footer/footer.component";
import Header from "../../components/header/header.component";
import Messages from "../../components/messages/messages.component";

const Navigation = () => {
    return (
        <Fragment>
            <Header />
            <Messages />
            <Outlet />
            <Footer>World Translation Center</Footer>
        </Fragment>
    );
};

    
export default Navigation;