import styled from "styled-components";

export const HeaderOuterContainer = styled.div`
    padding: 0 8px;
    margin-bottom:16px;

    &:before, &:after {
        content:"";
        display:table;
        clear:both;
    }
`;
export const HeaderPadding = styled.div`
    padding:0 8px;
    float:left;
    width:100%;

    &:before, &:after {
        content:"";
        display:table;
        clearb:both;
    }
`;
export const HeaderStyles = styled.div`
    color: #757575;
    background-color:#FFF;
    box-shadow:0 4px 10px 0 rgb(0 0 0 / 20%),
    0 4px 20px 0 rgb(0 0 0 / 19%);
`;
export const HeaderContainer = styled.div`
    position:relative;
    overflow:hidden;
`;