import styled from "styled-components";

export const CheckboxSectionContainer = styled.div`
    height:${({height})=>`${height}px`};
    overflow-y:scroll;
    width:100%;
    border-radius:5px;
    border:1px solid #CCC;
    padding:8px;

    &:before, &:after {
        display:table;
        clear:both:
        content:"";
    }
`;

export const CheckboxSectionStyles = styled.div`
    column-count:1;
    column-gap:12px;
    @media(min-width:601px) {
        column-count:3;
    }
    
`;
export const CheckboxRow = styled.span`
    width:100%;
    float:left;
`;
