import styled from "styled-components";

export const SelectPageContainer = styled.select`
    position:absolute;
    top:-64px;
    right:12px;
    padding:6px 12px;
    border:1px solid #CCC;
    border-radius:5px;
    color:#757575;
`;
export const SelectPageOption = styled.option`
    color:#757575;
`;