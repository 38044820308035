import { TableStyles} from "./table.styles";
import TableHeader from "../table-header/table-header.component";
import TableRow from "../table-row/table-row.component";

const Table = ({tableData}) => {
    const {header,rows, widths} = tableData;
    return (
        <TableStyles>
            <thead>
            <tr>
                {
                    header && header.map((ele,idx)=><TableHeader key={`tableheader${idx}`} value={ele} width={`${widths[idx]}`} />)
                }
            </tr>
            </thead>
            <tbody>
            {
                rows && rows.map((row,idx)=><TableRow key={`tablerow${idx}`} row={row} />)
            }
            </tbody>
        </TableStyles>
    );
};
export default Table;