import { LargeTitleWrapper, SmallTitleWrapper } from "./section-title.styles";
import Icon from "../icon/icon.component";

const SectionTitle = ({size,icon,children}) => {
    switch(size) {
        case "small":
        default:
            return (
                <SmallTitleWrapper>
                    <Icon icon={icon} color="#2196F3" /> {children}
                </SmallTitleWrapper>
            );
        case "large":
            return(
                <LargeTitleWrapper>
                    <Icon icon={icon} color="#2196F3" size="36" /> {children}
                </LargeTitleWrapper>
            );
    }
};
export default SectionTitle;