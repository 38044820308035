import { createAction } from "../../utils/reducer/reducer.utils";
import { PROJECT_LIST_ACTION_TYPES } from "./project-list.types";
import {
  AWSRequest,
  AWSRequestMulti,
  AWSRequestMultiFileUpload,
} from "../../utils/AWS/aws.utils";
import { resetUser } from "../user/user.actions";

export const setProjectList = data =>
  createAction(PROJECT_LIST_ACTION_TYPES.SET_PROJECT_LIST, data);

export const setPageCount = count =>
  createAction(PROJECT_LIST_ACTION_TYPES.SET_PAGE_COUNT, count);

export const setQueryOptions = options =>
  createAction(PROJECT_LIST_ACTION_TYPES.SET_QUERY_OPTIONS, options);

export const resetQueryOptions = () =>
  createAction(PROJECT_LIST_ACTION_TYPES.RESET_QUERY_OPTIONS);

export const setQueryOptionsPageNum = num =>
  createAction(PROJECT_LIST_ACTION_TYPES.SET_QUERY_OPTIONS_PAGE_NUM, num);

export const setQueryOptionsLimitStart = limitStart =>
  createAction(
    PROJECT_LIST_ACTION_TYPES.SET_QUERY_OPTIONS_LIMIT_START,
    limitStart
  );
export const resetDownloadFileUrl = () =>
  createAction(PROJECT_LIST_ACTION_TYPES.RESET_DOWNLOAD_FILE_URL);
export const fetchDownloadFileUrlSuccess = url =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_DOWNLOAD_FILE_URL, url);
export const fetchDownloadFileUrlError = error =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_DOWNLOAD_FILE_URL_ERROR, error);
export const fetchFileDownloadUrlAsync = params => async dispatch => {
  try {
    const response = await AWSRequest(
      params.data,
      "https://clients-endpoint.worldtranslationcenter.com/v1/get-file",
      params.token
    );
    switch (response.statusCode) {
      case 200:
        dispatch(fetchDownloadFileUrlSuccess(response.url));
        break;
      case 500:
      default:
        dispatch(
          fetchDownloadFileUrlError({
            message: "The file could not be downloaded.",
            name: "NOTABLETODOWNLOAD",
          })
        );
        break;
    }
  } catch (error) {
    dispatch(
      fetchDownloadFileUrlError({
        message: "The file could not be downloaded.",
        name: "NOTABLETODOWNLOAD",
      })
    );
  }
};

export const fetchProjectListStart = () =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_PROJECTS_START);

export const fetchProjectListSuccess = data =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_PROJECTS_SUCCESS, data);

export const fetchProjectListFailed = error =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_PROJECTS_FAILED, error);

export const fetchProjectListAsync = params => async dispatch => {
  dispatch(fetchProjectListStart());
  try {
    const data = await AWSRequest(
      params.data,
      "https://clients-endpoint.worldtranslationcenter.com/v1/list-jobs",
      params.token
    );
    /*
      const data = {
        statusCode:200|402|500,
        pageCount:n,
        pages:[[{
          jobID: encrypted job id,
          jobNumber,
          jobStart: unixts,
          jobDead: unixts,
          jobName,
          status
        }]], //array of pages containing an array of rows for each page - we only call for a single page so this is irrelevant
      }
      */

    switch (data.statusCode) {
      case 200:
        if (data.pageCount === 0) {
          //?? successful query but no matches, set notice message and return;
          dispatch(fetchProjectListSuccess([]));
          dispatch(setPageCount(data.newPageCount));
          return;
        }
        const pages = data.pages.flatMap(ele => ele);
        dispatch(fetchProjectListSuccess(pages));
        dispatch(setPageCount(data.newPageCount));
        break;
      case 402:
        //user token has probably expired
        //set currentUser=null
        //redirect to login should be handled by Home.component
        dispatch(
          fetchProjectListFailed({
            message: "Your token has expired.",
            name: "EXPIRED",
          })
        );
        //dispatch(setPageCount(data.newPageCount));
        dispatch(resetUser());
        break;
      case 500:
      default:
        dispatch(
          fetchProjectListFailed({
            message:
              "An error has occured. Please refresh the page. If the error persists, contact your project manager.",
            name: "UNKNOWNPROJECTLISTERROR",
          })
        );
        break;
    }
  } catch (error) {
    dispatch(
      fetchProjectListFailed({
        message: "An unknown error has occured.",
        name: "UNKNOWN",
      })
    );
  }
};

export const fetchProjectDetailsStart = () =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_PROJECT_DETAILS_START);

export const fetchProjectDetailsSucceess = data =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_PROJECT_DETAILS_SUCCESS, data);

export const fetchProjectDetailsFailed = error =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_PROJECT_DETAILS_FAILED, error);

export const fetchProjectDetailsAsync = params => async dispatch => {
  dispatch(fetchProjectDetailsStart());
  try {
    const data = await AWSRequest(
      params.data,
      "https://clients-endpoint.worldtranslationcenter.com/v1/view-job",
      params.token
    );
    switch (data.statusCode) {
      case 200:
        dispatch(fetchProjectDetailsSucceess(data));
        break;
      case 400: //job not located, possibly a security threat
      case 401: //token expired
      case 500:
      default:
        dispatch(
          fetchProjectDetailsFailed({
            message:
              "We were unable to get the project details. Please try again.",
            name: "LOADDETAILSFAILED",
          })
        );
        break;
    }
  } catch (error) {
    dispatch(
      fetchProjectDetailsFailed({
        message: "An unknow error has occured",
        name: "UNKNOWN",
      })
    );
  }
};
export const fetchFileUploadDataStart = () =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_FILE_UPLOAD_DATA_START);
export const fetchFileUploadDataSuccess = data =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_FILE_UPLOAD_DATA_SUCCESS, data);
export const fetchFileUploadDataFailed = error =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_FILE_UPLOAD_DATA_FAILED, error);

export const fetchFileUploadDataAsync = params => async dispatch => {
  dispatch(fetchFileUploadDataStart());
  try {
    const data = Array.from({ length: params.count }).fill({
      urlType: "upload",
    });
    const response = await AWSRequestMulti(
      data,
      "https://clients-endpoint.worldtranslationcenter.com/v1/presigned",
      params.token
    );
    const signed = response
      .filter(ele => ele.statusCode === 200)
      .map(ro => ({ signed: ro.signed, uuid: ro.uuid }));
    if (signed.length === 0) {
      dispatch(
        fetchFileUploadDataFailed({
          message: "No results were returned.",
          name: "NOTRETURNED",
        })
      );
      return;
    }
    if (signed.length !== params.count) {
      dispatch(
        fetchFileUploadDataFailed({
          message: "An inccorect number of results were returned.",
          name: "FILEUPLOADDATAERROR",
        })
      );
      return;
    }
    dispatch(fetchFileUploadDataSuccess(signed));
  } catch (error) {
    console.log(error);
    dispatch(
      fetchFileUploadDataFailed({
        message: "The files could not be uploaded.",
        name: "UNKNOWFILEUPLOADDATA",
      })
    );
  }
};

export const fetchUploadStart = () =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_UPLOAD_START);
export const fetchUploadFailed = error =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_UPLOAD_FAILED, error);
export const fetchUploadSuccess = () =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_UPLOAD_SUCCESS);

export const fetchUploadAsync = params => async dispatch => {
  dispatch(fetchUploadStart());
  try {
    const response = await AWSRequestMultiFileUpload(params.data);
    const filtered = response.filter(ele => ele.status === 200);
    if (filtered.length !== params.data.length) {
      dispatch(
        fetchUploadFailed({
          message:
            "All files were not uploaded. Please contact your project manager.",
          name: "MISSINGFILESUPLOAD",
        })
      );
      return;
    }
    dispatch(fetchUploadSuccess());
  } catch (error) {
    console.log(error);
    dispatch(
      fetchUploadFailed({
        message: "An error occured. The files could not be uploaded.",
        name: "UNKNOWNUPLOADERROR",
      })
    );
  }
};
export const setFileUploadCompleteFlag = data =>
  createAction(PROJECT_LIST_ACTION_TYPES.SET_FILE_UPLOAD_COMPLETE, data);
export const refreshData = () =>
  createAction(PROJECT_LIST_ACTION_TYPES.REFRESH_DATA);
export const clearNewJobComplete = () =>
  createAction(PROJECT_LIST_ACTION_TYPES.CLEAR_NEW_JOB_COMPLETE);
export const fetchNewJobStart = () =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_NEW_JOB_START);
export const fetchNewJobSuccess = jobid =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_NEW_JOB_SUCCESS, jobid);
export const fetchNewJobFailed = error =>
  createAction(PROJECT_LIST_ACTION_TYPES.FETCH_NEW_JOB_FAILED, error);

export const fetchNewJobAsync = params => async dispatch => {
  dispatch(fetchNewJobStart());
  try {
    let endpoint =
      "https://clients-endpoint.worldtranslationcenter.com/v1/new-job";
    if (
      params?.data?.jobID !== 0 &&
      typeof params.data.jobID === "string" &&
      params.data.jobID.length > 10
    )
      endpoint =
        "https://clients-endpoint.worldtranslationcenter.com/v1/new-services";
    const response = await AWSRequest(params.data, endpoint, params.token);
    switch (response.statusCode) {
      case 200:
        dispatch(fetchNewJobSuccess());
        dispatch(
          fetchProjectDetailsAsync({
            data: { jobID: response.jobID },
            token: params.token,
          })
        );
        dispatch(refreshData());
        break;
      case 402: //not authorized
      case 500:
      default:
        dispatch(
          fetchNewJobFailed({
            message: "The job could not be added.",
            name: "JOBNOTADDED",
          })
        );
        dispatch(refreshData());
        break;
    }
  } catch (error) {
    console.log(error);
    dispatch(
      fetchNewJobFailed({
        message: "The job could not be added.",
        name: "UNKNOWNJOBNOTADDED",
      })
    );
  }
};
export const setFileData = data =>
  createAction(PROJECT_LIST_ACTION_TYPES.SET_FILE_DATA, data);

export const clearAllProjectListErrors = () =>
  createAction(PROJECT_LIST_ACTION_TYPES.CLEAR_ALL_ERRORS);
