import { AIP_ACTION_TYPES } from "./aip.types";

const INITIAL_STATE = {
  AIPProps: {
    colThird: { isOpen: 0 },
    colTwoThird: { isOpen: 1 },
    openers: [
      { id: 1, position: 1, isVisible: 1 },
      { id: 2, position: 1, isVisible: 1 },
      { id: 3, position: 2, isVisible: 0 },
      { id: 4, position: 2, isVisible: 0 },
    ],
    serviceModal: {
      icon: "fa fa-file-archive-o",
      isVisible: false,
      label: "Add Services",
      size: "small",
    },
    uploadProgressModal: {
      isVisible: false,
    },
    panels: [
      {
        color: "#757575",
        backgroundColor: "#FFF",
        shadowType: "1",
        bmargin: "16",
        openerID: "1",
        icon: "fa fa-question-circle-o",
        size: "small",
        label: "Search Projects",
      },
      {
        color: "#757575",
        backgroundColor: "#FFF",
        shadowType: "1",
        openerID: "2",
        icon: "fa fa-globe",
        size: "small",
        label: "Start a New Project",
      },
      {
        color: "#757575",
        backgroundColor: "#FFF",
        shadowType: "2",
        bmargin: "16",
        openerID: "3",
        icon: "fa fa-clone",
        size: "large",
        label: "Projects",
      },
      {
        color: "#757575",
        backgroundColor: "#FFF",
        shadowType: "2",
        openerID: "4",
        icon: "fa fa-object-group",
        size: "large",
        label: "Project Details",
      },
      {
        color: "#757575",
        backgroundColor: "#FFF",
        shadowType: "2",
        bmargin: "16",
        icon: "fa fa-exclamation-triangle",
        size: "small",
        label: "Messages",
      },
    ],
  },
};
export const AIPReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case AIP_ACTION_TYPES.SET_AIP_PROPS:
      return {
        ...state,
        AIPProps: payload,
      };
    case AIP_ACTION_TYPES.FLIP_OPENERS:
      return {
        ...state,
        AIPProps: {
          ...state.AIPProps,
          openers: state.AIPProps.openers.map(opener => ({
            ...opener,
            isVisible: opener.isVisible ? 0 : 1,
          })),
        },
      };
    case AIP_ACTION_TYPES.SET_UPLOAD_PROGRESS_MODAL_VISIBLE:
      return {
        ...state,
        AIPProps: {
          ...state.AIPProps,
          uploadProgressModal: {
            ...state.AIPProps.uploadProgressModal,
            isVisible: payload,
          },
        },
      };
    default:
      return state;
  }
};
