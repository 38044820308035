import styled from "styled-components";

export const RowInnerStyles = styled.div`
    width: 100%;
    float:left;
    margin-bottom:12px;
    cursor:${(props)=>props.onClick ? "pointer" : "auto"};

    &:before, &:after {
        content:"";
        display:table;
        clear:both;
    }
`;