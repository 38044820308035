import { Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cover from "../cover/cover.component";
import { ModalStyles } from "./modal.styles";
import ModalClose from "../modal-close/modal-close.component";

import { selectAIPProps } from "../../store/aip/aip.selector";
import { setAIPProps } from "../../store/aip/aip.actions";

const Modal = ({children, modalId="serviceModal", ...otherProps}) => {
    //const {AIPProps, setAIPProps} = useContext(AIPContext);
    const dispatch = useDispatch();
    const AIPProps = useSelector(selectAIPProps);

    const modalController = AIPProps[modalId];
    const {isVisible} = modalController;
    const coverClickHandler = () => {
        const newProps = {...AIPProps};
        newProps[modalId].isVisible = false;
        dispatch(setAIPProps(newProps));
    };
    return(
        <Fragment><ModalStyles isVisible={isVisible} {...otherProps}><ModalClose onClick={coverClickHandler} />{children}</ModalStyles><Cover isVisible={isVisible} onClick={coverClickHandler} /></Fragment>
    );
}
export default Modal;