import Container from "../container/container.component";
import SectionTitle from "../section-title/section-title.component";
import InnerContainer from "../inner-container/inner-container.component";
import SlideWrapper from "../slide-wrapper/slide-wrapper.component";
import HR from "../hr/hr.component";
import Opener from "../opener/opener.component";

const Panel = ({children, ...otherProps}) => {
    const {color="#757575", backgroundColor="#FFF",shadowType="1",bmargin="0",openerID="",icon="",size="large",label=""} = otherProps;
    return (
        <Container color={color} backgroundColor={backgroundColor} shadowType={shadowType} bmargin={bmargin}> {openerID && <Opener openerID={openerID} /> }
            <SectionTitle icon={icon} size={size}>{label}</SectionTitle>
            <InnerContainer>
                <SlideWrapper>
                    {children}
                </SlideWrapper>
                <HR />
            </InnerContainer>
        </Container>
    );
};
export default Panel;