import { PROJECT_FORM_FIELDS_ACTION_TYPES } from "./project-form-fields.types";
import { createAction } from "../../utils/reducer/reducer.utils";

export const setProjectFormFields = data =>
  createAction(PROJECT_FORM_FIELDS_ACTION_TYPES.SET_PROJECT_FORM_FIELDS, data);

export const addProjectFormFieldsRaw = raw =>
  createAction(PROJECT_FORM_FIELDS_ACTION_TYPES.ADD_RAW, raw); //raw is array of file objects

export const addProjectFormFieldsFiles = files =>
  createAction(PROJECT_FORM_FIELDS_ACTION_TYPES.ADD_FILES, files); //files is an array of file objects

export const addProjectFormFieldsServicesDataRow = row =>
  createAction(PROJECT_FORM_FIELDS_ACTION_TYPES.ADD_SERVICES_DATA_ROW, row); //row is an object

export const clearProjectFormFields = () =>
  createAction(PROJECT_FORM_FIELDS_ACTION_TYPES.CLEAR_FIELDS);

export const setProjectFormFieldsField = (name, value) =>
  createAction(PROJECT_FORM_FIELDS_ACTION_TYPES.SET_FIELD, { name, value });
