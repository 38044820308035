import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Row from "../../components/row/row.component";
import ColThird from "../../components/col-third/col-third.component";
import ColTwoThird from "../../components/col-two-third/col-two-third.component";
import SearchForm from "../../components/search-form/search-form.component";
import ProjectForm from "../../components/project-form/project-form.component";
import Panel from "../../components/panel/panel.component";
import ProjectDetails from "../../components/project-details/project-details.component";
import ServiceModal from "../../components/service-modal/service-modal.component";
import Spinner from "../../components/spinner/spinner.component";
import { selectProjectIsLoading, selectQueryOptions } from "../../store/project-list/project-list.selector";
import { selectCurrentUser } from "../../store/user/user.selector";
import ProjectListComponent from "../../components/project-list/project-list.component";

import { fetchProjectListAsync } from "../../store/project-list/project-list.actions";

import { tokenExpired } from "../../utils/dates/date.utils";
import { setCurrentUser } from "../../store/user/user.actions";
import { getToken } from "../../store/user/user.selector";
import { selectAIPPanels } from "../../store/aip/aip.selector";
import { selectMessages } from "../../store/messages/messages.selector";
import { setMessagesTimeout } from "../../store/messages/messages.actions";
import { fetchServiceOptionsAsync } from "../../store/service-options/service-options.actions";

const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector(selectCurrentUser);
    const messages = useSelector(selectMessages);
    const projectListIsLoading = useSelector(selectProjectIsLoading);
    const panels = useSelector(selectAIPPanels);
    const token = useSelector(getToken);
    const queryOptions = useSelector(selectQueryOptions);
    useEffect(()=>{
        if (token == null) return;
        dispatch(fetchProjectListAsync({data:{...queryOptions},token:token}));
    },[token]);
    
    useEffect(()=>{
        if (!currentUser?.token || currentUser.token === "") {
            navigate("/login");
        } else {
            if (tokenExpired(currentUser.expires)) {
                dispatch(setCurrentUser({}));
                dispatch(setMessagesTimeout([...messages,"Your session has expired, please login."],5000));
                navigate("/login");
            } 
        }
    },[currentUser]);
    useEffect(()=>{
        if (token == null) return;
        dispatch(fetchServiceOptionsAsync(token));
    },[token]);
    return (
        <Row>
            <ColThird>
                <Panel {...panels[0]}><SearchForm /></Panel>
                <Panel {...panels[1]}><ProjectForm /></Panel>
            </ColThird>
            <ColTwoThird>
                <Panel {...panels[2]}>
                    {
                        projectListIsLoading ? (<Spinner />) : (<ProjectListComponent />)
                    }
                </Panel>
                <Panel {...panels[3]}><ProjectDetails /></Panel>
            </ColTwoThird>
            <ServiceModal />
        </Row>
    );
    
};
export default Home;