import { Fragment, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import DetailsH2 from "../details-h2/details-h2.components";
import RowInner from "../row-inner/row-inner.component";
import ColHalf from "../col-half/col-half.component";
import DetailsDataElement from "../details-data-element/details-data-element.component";
import FilesContainer from "../files-container/files-container.component";
import { dateStringFromUnix, dateInputFromUnix } from "../../utils/dates/date.utils";
import { selectProjectDetailsFileMap } from "../../store/project-list/project-list.selector";
import Table from "../table/table.component";
import { DetailsDataElementLabel, DetailsDataElementData } from "../../components/details-data-element/details-data-element.styles";
import Icon from "../icon/icon.component";
import { selectProjectFormFields } from "../../store/project-form-fields/project-form-fields.selector";
import { setProjectFormFields } from "../../store/project-form-fields/project-form-fields.actions";
import { selectAIPProps } from "../../store/aip/aip.selector";
import { setAIPProps, flipOpeners } from "../../store/aip/aip.actions";

const ProjectDetailsPanel = ({projectDetails}) => {
    const dispatch = useDispatch();
    const projectFormFields = useSelector(selectProjectFormFields);
    const fileTableMap = useSelector(selectProjectDetailsFileMap);
    const AIPProps = useSelector(selectAIPProps);
    const AIPPropsRef = useRef(AIPProps);
    const projectFormFieldsRef = useRef(projectFormFields);
    if (!projectDetails?.details?.jobNumber) return (<div>Select a project above to view the details.</div>);
    
    const {details:{jobName,jobNumber,status,timestamp,jobStart,jobDead,jobDesc,jobID},completedFiles,pms} = projectDetails;
    const startDateStr = dateInputFromUnix(jobStart);
    const jobDeadStr = dateInputFromUnix(jobDead);
    const setAIPPropsRef = data => {
        AIPPropsRef.current = data;
        dispatch(setAIPProps(data));
    }
    const setProjectFormFieldsRef = data => {
        projectFormFieldsRef.current = data;
        dispatch(setProjectFormFields(data));
    }
    const handleAddServices = () => {
        setProjectFormFieldsRef({...projectFormFieldsRef.current, name:jobName, startDate:startDateStr, deadline:jobDeadStr, manager:pms.join(" & "), description: jobDesc, readonly:true, encid:jobID});
        if (AIPPropsRef?.current?.colThird?.isOpen !== 1) setAIPPropsRef({...AIPPropsRef.current, colThird:{isOpen:1}, colTwoThird:{isOpen:0}, serviceModal:{...AIPPropsRef.current.serviceModal, isVisible:true}});
        dispatch(flipOpeners());
    };

    return (
        <Fragment>
            <DetailsH2 projectNumber={jobNumber} />
            <RowInner>
                <ColHalf>
                    <DetailsDataElement label="Project Title" data={jobName} />
                </ColHalf>
                <ColHalf>
                    <DetailsDataElement label="Created On" data={dateStringFromUnix(timestamp)} />
                </ColHalf>
            </RowInner>
            <RowInner>
                <ColHalf>
                    <DetailsDataElement label="Project Status" data={status} />
                </ColHalf>
                <ColHalf>
                    <DetailsDataElement label="Start Date" data={dateStringFromUnix(jobStart)} />
                </ColHalf>
            </RowInner>
            <RowInner>
                <ColHalf>
                    <DetailsDataElement label="Project Managers" data="" dataArray={pms} />
                </ColHalf>
                <ColHalf>
                    <DetailsDataElement label="Deadline" data={dateStringFromUnix(jobDead)} />
                </ColHalf>
            </RowInner>
            <RowInner>
                <DetailsDataElement label="Description" data={jobDesc} />
            </RowInner>
            <RowInner onClick={handleAddServices}>
                    Add Services <Icon icon="fa fa-plus-square" color="#2196F3" />
            </RowInner>
            <RowInner>
                <DetailsDataElementLabel>Selected Services</DetailsDataElementLabel>
                {
                    fileTableMap.rows.length ? <Table tableData={fileTableMap} /> : <DetailsDataElementData>The service list is not available.</DetailsDataElementData>
                }
            </RowInner>
            <RowInner>
                <FilesContainer label="Delivered Files" files={completedFiles} />
            </RowInner>
        </Fragment>
    );
};
export default ProjectDetailsPanel;