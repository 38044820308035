export const USER_ACTION_TYPES = {
  SET_CURRENT_USER: "user/SET_CURRENT_USER",
  FETCH_LOGIN_START: "user/FETCH_LOGIN_START",
  FETCH_LOGIN_SUCCESS: "user/FETCH_LOGIN_SUCCESS",
  FETCH_LOGIN_FAILED: "user/FETCH_LOGIN_FAILED",
  CLEAR_ERROR: "user/CLEAR_ERROR",
  RESET_USER: "user/RESET_USER",
  FETCH_USER_REGISTRATION_START: "user/FETCH_USER_REGISTRATION_START",
  FETCH_USER_REGISTRATION_SUCCESS: "user/FETCH_USER_REGISTRATION_SUCCESS",
  FETCH_USER_REGISTRATION_FAILED: "user/FETCH_USER_REGISTRATION_FAILED",
  FETCH_PASSWORD_UPDATE_START: "user/FETCH_PASSWORD_UPDATE_START",
  FETCH_PASSWORD_UPDATE_SUCCESS: "user/FETCH_PASSWORD_UPDATE_SUCCESS",
  FETCH_PASSWORD_UPDATE_FAILED: "user/FETCH_PASSWORD_UPDATE_FAILED",
  SET_HAS_UPDATED: "user/SET_HAS_UPDATED",
  SET_ENC_LOGIN: "user/SET_ENC_LOGIN",
  SET_CHECK_ENCODED: "user/SET_CHECK_ENCODED",
  SET_ENC: "user/SET_ENC",
};
