import { createAction } from "../../utils/reducer/reducer.utils";
import { MESSAGES_TYPES } from "./messages.types";

export const setMessages = data =>
  createAction(MESSAGES_TYPES.SET_MESSAGE, data);

export const setErrors = data => createAction(MESSAGES_TYPES.SET_ERROR, data);

export const clearMessages = () => createAction(MESSAGES_TYPES.CLEAR_MESSAGES);

export const clearErrors = () => createAction(MESSAGES_TYPES.CLEAR_ERRORS);

export const clearAll = () => createAction(MESSAGES_TYPES.CLEAR_ALL);

export const addError = errorMsg =>
  createAction(MESSAGES_TYPES.ADD_ERROR, errorMsg);

export const setMessagesTimeout = (messages, delay) => dispatch => {
  dispatch(setMessages(messages));
  setTimeout(() => {
    dispatch(clearMessages());
  }, delay);
};
