import { createSelector } from "reselect";
import { getDate, formatInputDatePair } from "../../utils/dates/date.utils";

export const selectProjectFormFieldsReducer = state => state.pff;

export const selectProjectFormFields = createSelector(
  [selectProjectFormFieldsReducer],
  slice => slice.projectFormFields
);
export const selectProjectFormFieldsValues = createSelector(
  [selectProjectFormFieldsReducer],
  slice => ({
    name: slice.projectFormFields.name,
    manager: slice.projectFormFields.manager,
    description: slice.projectFormFields.description,
    startDate: slice.projectFormFields.startDate,
    deadline: slice.projectFormFields.deadline,
    readonly: slice.projectFormFields.readonly,
    encid: slice.projectFormFields.encid,
  })
);

export const selectProjectFormFieldsDefDates = createSelector(
  [selectProjectFormFieldsReducer],
  slice => {
    const today = getDate(new Date());
    const defaultDatePair = formatInputDatePair(today, { days: 30 });
    const { startDate, endDate } = defaultDatePair;
    const sDate = slice?.projectFormFields?.startDate
      ? slice.projectFormFields.startDate
      : startDate;
    const eDate = slice?.projectFormFields?.deadline
      ? slice.projectFormFields.deadline
      : endDate;
    return {
      name: slice.projectFormFields.name,
      manager: slice.projectFormFields.manager,
      description: slice.projectFormFields.description,
      startDate: sDate,
      deadline: eDate,
      readonly: slice.projectFormFields.readonly,
      encid: slice.projectFormFields.encid,
    };
  }
);
