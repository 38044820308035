import styled from "styled-components";

export const FooterStyles = styled.div`
    width:98%;
    height:30px;
    max-width:1400px;
    color:#FFF;
    padding:5px 0 0 0;
    background-color:#2196F3;
    font-size:0.8em;
    text-align:center;
    margin:16px auto 0 auto;
`;