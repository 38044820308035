import { createSelector } from "reselect";

export const selectMessagesReducer = state => state.mes;

export const selectMessages = createSelector(
  [selectMessagesReducer],
  slice => slice.messages
);
export const selectErrors = createSelector(
  [selectMessagesReducer],
  slice => slice.errors
);
