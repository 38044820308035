import styled from "styled-components";

export const InputSelectStyles = styled.select`
    width:${({width})=>`${width}px`};
    text-transform:none;
    font:inherit;
    margin:0;
    border-color:#CCC;
    border-width:1px;
    border-radius:5px;
    padding:4px;
`;