import { Fragment, useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";

import RowInner from "../row-inner/row-inner.component";
import Form from "../form/form.component";
import ColHalf from "../col-half/col-half.component";
import Input from "../input/input.component";
import FormButton from "../form-button/form-button.component";
import Icon from "../icon/icon.component";
import ButtonText from "../button-text/button-text.component";
import Textarea from "../textarea/textarea.component";
import Table from "../table/table.component";
import Loading from "../loading/loading.component";
import Progress from "../progress/progress.component";

import { setAIPProps, flipOpeners } from "../../store/aip/aip.actions";
import { setProjectFormFieldsField, clearProjectFormFields } from "../../store/project-form-fields/project-form-fields.actions";
import { resetProjectFormServices } from "../../store/project-form-services/project-form-services.actions";
import { setMessagesTimeout } from "../../store/messages/messages.actions";
import { fetchFileUploadDataAsync, fetchUploadAsync, fetchNewJobAsync, setFileData, clearNewJobComplete } from "../../store/project-list/project-list.actions";

import { selectAIPProps } from "../../store/aip/aip.selector";
import { selectProjectFormServices } from "../../store/project-form-services/project-form-services.selector";
import { selectProjectFormFieldsDefDates } from "../../store/project-form-fields/project-form-fields.selector";
import { selectMessages } from "../../store/messages/messages.selector";
import { getToken } from "../../store/user/user.selector";
import { selectProjectIsLoading, selectFileUploadInitiated, selectFileUploadData, selectFileUploadComplete, selectFileData, selectIsFileUploading, selectNewJobComplete } from "../../store/project-list/project-list.selector";

import { timestampFromInputDate } from "../../utils/dates/date.utils";

const ProjectForm = () => {
    const dispatch = useDispatch();

    const [ uploadProperties, setUploadProperties ] = useState([]);

    const uploadPropertiesRef = useRef(uploadProperties);

    const projectFormFields = useSelector(selectProjectFormFieldsDefDates);
    const messages = useSelector(selectMessages);
    const token = useSelector(getToken);
    const isLoading = useSelector(selectProjectIsLoading);
    const fileUploadInitiated = useSelector(selectFileUploadInitiated);
    const fileUploadData = useSelector(selectFileUploadData);
    const fileUploadComplete = useSelector(selectFileUploadComplete);
    const fileData = useSelector(selectFileData);
    const AIPProps = useSelector(selectAIPProps);
    const isFileUploading = useSelector(selectIsFileUploading);
    const isNewJobComplete = useSelector(selectNewJobComplete);
    const projectFormServices = useSelector(selectProjectFormServices);

    const hasSerivceRows = projectFormServices.rows.length ? true : false;
    const {name, manager, description, startDate, deadline, readonly, encid} = projectFormFields;

    const setUploadPropertiesRef = data => {
        uploadPropertiesRef.current = data;
        setUploadProperties(data);
    };
    const resetFormFields = () => {
        dispatch(clearProjectFormFields());
        dispatch(resetProjectFormServices());
    };
    const handleFileUploadProgress = (fileidx) => (progressEvent) => { 
        const {loaded, total} = progressEvent;
        const percent = Math.floor((loaded * 100)/total);
        uploadPropertiesRef.current[fileidx] = percent;
        setUploadPropertiesRef([...uploadPropertiesRef.current]);
    }

    useEffect(()=>{
        if (isNewJobComplete) {
            if (AIPProps.colThird.isOpen === 1) {
                dispatch(setAIPProps({...AIPProps,colThird:{isOpen:0},colTwoThird:{isOpen:1}}));
                dispatch(flipOpeners());
            }
            dispatch(clearProjectFormFields());
            dispatch(resetProjectFormServices());
            setUploadPropertiesRef([]);
            dispatch(clearNewJobComplete());
        }
    },[isNewJobComplete,AIPProps,dispatch]);

    useEffect(()=>{
        if (isFileUploading  && !AIPProps.uploadProgressModal.isVisible) {
            dispatch(setAIPProps({...AIPProps,uploadProgressModal:{isVisible:true}}));
        } else if (!isFileUploading && AIPProps.uploadProgressModal.isVisible) {
            dispatch(setAIPProps({...AIPProps,uploadProgressModal:{isVisible:false}}));
        }
    },[isFileUploading, AIPProps, dispatch]);

    useEffect(()=>{
        if (!fileUploadComplete) return;
        const data = {
            jobID:encid,
            startdate:timestampFromInputDate(startDate),
            deadline:timestampFromInputDate(deadline),
            filedata:fileData,
            pm:manager,
            title:name,
            desc:description,
            services:projectFormServices.data.map(svc=>({
                source:svc.sourcelanguage,
                notes:svc.notes.value,
                filenames:svc.filenames,
                services:svc.services,
                deliveryformat:svc.delivery,
                languages:svc.dest
            }))
        };
        const params = {
            data,
            token
        }
        dispatch(fetchNewJobAsync(params));
    },[fileUploadComplete]);
    
    useEffect(()=>{
        if (!fileUploadInitiated) return;
        const callData = projectFormServices.rawfiles.map((ele,idx)=>({data:ele.file,endpoint:fileUploadData[idx].signed,filename:ele.filename,fileuuid:fileUploadData[idx].uuid,idx,updater:handleFileUploadProgress(idx)}));
        const baseProgress = projectFormServices.rawfiles.map(ele=>0);
        const fileData = callData.map((ele)=>({fileuuid:ele.fileuuid,filename:ele.filename}));
        const params = {
            data:callData
        };
        setUploadPropertiesRef(baseProgress);
        dispatch(fetchUploadAsync(params));
        dispatch(setFileData(fileData));
    },[fileUploadInitiated]);

    
    const updateAIPProps = () => {
        dispatch(setAIPProps({...AIPProps, serviceModal:{...AIPProps.serviceModal,isVisible:true}}));
    };
    const handleModalClick = () => {
        updateAIPProps();
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!hasSerivceRows) {
            dispatch(setMessagesTimeout([...messages,"Please add a service to create a new project."],4000));
            return;
        }
        dispatch(fetchFileUploadDataAsync({count:projectFormServices.rawfiles.length,token}));
        //resetFormFields();
    };
    const handleChange = (event) =>{
        const { name, value } = event.target;
        dispatch(setProjectFormFieldsField(name,value));
    };
    const buttonTextStr = encid === 0 ? "Create Project" : "Add Services";
    return (
        <Fragment>
        <Form onSubmit={handleSubmit}>
            <RowInner>
                <ColHalf>
                    <label>Project Name<br />
                        <Input name="name" value={name} onChange={handleChange} readOnly={readonly} />
                    </label>
                </ColHalf>
                <ColHalf>
                    <label>Project Manager (Optional)<br />
                        <Input name="manager" value={manager} onChange={handleChange} readOnly={readonly} />
                    </label>
                </ColHalf>
            </RowInner>
            <RowInner>
                <label>Description<br />
                    <Textarea name="description" value={description} onChange={handleChange} readOnly={readonly} />
                </label>
            </RowInner>
            <RowInner>
                <ColHalf>
                    <label>Start Date<br />
                        <Input type="date" name="startDate" value={startDate} onChange={handleChange} readOnly={readonly} />
                    </label>
                </ColHalf>
                <ColHalf>
                    <label>Deadline<br />
                        <Input type="date" name="deadline" value={deadline} onChange={handleChange} readOnly={readonly} />
                    </label>
                </ColHalf>
            </RowInner>
            <RowInner>
                <RowInner onClick={handleModalClick}>
                    Services <Icon icon="fa fa-plus-square" color="#2196F3" />
                </RowInner>
                <RowInner>
                    {
                        hasSerivceRows ? <Table tableData={projectFormServices} /> : "No services selected. Please add files / services to continue."
                    }
                </RowInner>
            </RowInner>
            <RowInner>
                <ColHalf>
                <FormButton type="submit">
                    <Icon icon="fa fa-object-ungroup" color="#FFF" /><ButtonText text={buttonTextStr} color="#FFF" />
                </FormButton>
                </ColHalf>
                <ColHalf>
                    <FormButton type="button" onClick={resetFormFields}>
                        <Icon icon="fa fa-refresh" color="#FFF" /><ButtonText text="Reset" color="#FFF" />
                    </FormButton>
                </ColHalf>
            </RowInner>
        </Form>
        <Loading isVisible={isLoading} />
        <Progress fileData={projectFormServices.rawfiles.map(ele=>ele.filename)} progressData={uploadPropertiesRef.current} />
        </Fragment>
    );
};
export default ProjectForm;