import styled from "styled-components";

export const TableRowElementStyles = styled.td`
    padding:5px 0 5px 6px;
    vertical-align:top;
    color:#000;
    opacity:70%;
    &:first-child {
        cursor:pointer;
    }
`;