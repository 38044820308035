import { InputSelectStyles } from "./input-select.styles";

const InputSelect = ({width="360",options=[],name,currentValue,onChange=""}) => {
    return (
        <InputSelectStyles name={name} width={width} onChange={onChange} value={currentValue}>
            {
                options.map((ele,idx)=><option key={idx} value={ele.value}>{ele.label}</option>)
            }
        </InputSelectStyles>
    );
};
export default InputSelect;