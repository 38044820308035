import { createAction } from "../../utils/reducer/reducer.utils";
import { PROJECT_FORM_SERVICES_ACTION_TYPES } from "./project-form-services.types";

export const setProjectFormServices = data =>
  createAction(
    PROJECT_FORM_SERVICES_ACTION_TYPES.SET_PROJECT_FORM_SERVICES,
    data
  );
export const resetProjectFormServices = () =>
  createAction(PROJECT_FORM_SERVICES_ACTION_TYPES.RESET_SERVICES);
