import {
  format,
  add,
  parseISO,
  parse,
  millisecondsToSeconds,
  secondsToMilliseconds,
  compareAsc,
} from "date-fns";

export const formatInputDate = date => {
  return format(date, "yyyy-MM-dd");
};
export const formatDateString = date => {
  return format(date, "MMM, d yyyy");
};
export const formatInputDatePair = (date, increment) => {
  return {
    startDate: formatInputDate(date),
    endDate: formatInputDate(add(date, increment)),
  };
};
export const getDate = date => {
  return parseISO(date.toISOString());
};
export const getDateNonISO = date => {
  return parse(date, "yyyy-MM-dd", new Date());
};
export const timestampFromInputDate = date => {
  return millisecondsToSeconds(getDateNonISO(date).getTime());
};
export const dateStringFromUnix = ts => {
  return formatDateString(new Date(secondsToMilliseconds(ts)));
};
export const futureTimestamp = increment => {
  return add(getDate(new Date()), increment).getTime();
};
export const tokenExpired = expires => {
  const date = getDate(new Date(expires));
  const now = getDate(new Date());
  return compareAsc(date, now) === -1 ? true : false;
};
export const dateInputFromUnix = ts => {
  return formatInputDate(new Date(secondsToMilliseconds(ts)));
};
