import { SERVICE_OPTIONS_TYPES } from "./service-options.types";
import { createAction } from "../../utils/reducer/reducer.utils";
import { AWSRequest } from "../../utils/AWS/aws.utils";

export const fetchServiceOptionsStart = () =>
  createAction(SERVICE_OPTIONS_TYPES.FETCH_SERVICE_OPTIONS_START);

export const fetchServiceOptionsFailed = error =>
  createAction(SERVICE_OPTIONS_TYPES.FETCH_SERVICE_OPTIONS_FAILED, error);

export const fetchServiceOptionsSuccess = data =>
  createAction(SERVICE_OPTIONS_TYPES.FETCH_SERVICE_OPTIONS_SUCCESS, data);

export const fetchServiceOptionsAsync = token => async dispatch => {
  dispatch(fetchServiceOptionsStart());
  const endpoint =
    "https://clients-endpoint.worldtranslationcenter.com/v1/new-job-lists";
  try {
    const response = await AWSRequest({}, endpoint, token);
    switch (response.statusCode) {
      case 200:
        dispatch(
          fetchServiceOptionsSuccess({
            services: response.services,
            languages: response.languages,
            formats: response.formats,
          })
        );
        break;
      case 500:
      default:
        dispatch(
          fetchServiceOptionsFailed({
            message: "An unknown error occured.",
            name: "UNKNOWN",
          })
        );
        break;
    }
  } catch (error) {
    dispatch(
      fetchServiceOptionsFailed({
        message: "An unknown error occured.",
        name: "UNKNOWN",
      })
    );
  }
};
export const clearServiceOptionsError = () =>
  createAction(SERVICE_OPTIONS_TYPES.CLEAR_SERVICE_OPTIONS_ERROR);
