import { Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../loading/loading.component";
import { LoginBoxPage, LoginBoxStyles, LoginBoxRow } from "../login-box/login-box.styles";
import SectionTitle from "../section-title/section-title.component";
import { selectCurrentUser, selectUserIsLoading } from "../../store/user/user.selector";
import Form from "../form/form.component";
import Input from "../input/input.component";
import FormButton from "../form-button/form-button.component";
import ButtonText from "../button-text/button-text.component";
import Icon from "../icon/icon.component";
import PasswordIcon from "../password-icon/password-icon.component";
import { setErrors } from "../../store/messages/messages.actions";
import { InputRegistrationEmail } from "./registration-panel.styles";
import { fetchPasswordUpdateAsync } from "../../store/user/user.actions";

const RegistrationPanel = () => {
    const dispatch = useDispatch();
    const currentUser = useSelector(selectCurrentUser);
    const userIsLoading = useSelector(selectUserIsLoading);
    const [regFields,setRegFields] = useState({password:"",confirm:"",p1type:1,p1isVisible:false,p1message:"",p2type:1,p2isVisible:false,p2message:""});
    const {password,confirm,p1type,p2type,p1isVisible,p2isVisible,p1message,p2message} = regFields;
    const minStyles = {
        minHeight:"500px"
    };
    if (currentUser == null && userIsLoading) return <div style={minStyles}><Loading isVisible={userIsLoading} /></div>;
    if (currentUser == null) return;
    const {token="",email="",updatePassword=0} = currentUser;
    const title = updatePassword ? "Update Password" : "Register";
    const buttonLabel = updatePassword ? "Update Password" : "Complete Registration";

    const handleChange = (event) => {
        const {name,value} = event.target;
        setRegFields({...regFields,[name]:value});
    }
    const doPasswordsMatch=(p1,p2)=> {
        return p1 === p2;
    }
    const passwordMatch = (event) => {
        if (event.target.name === "password") {
            if (regFields.password.length < 8) {
                setRegFields({...regFields,p1type:0,p1isVisible:true,p1message:"Password must be at least 8 characters."});
            } else {
                setRegFields({...regFields,p1type:1,p1isVisible:true, p1message:"Password is good!"});
                if (regFields.confirm.length >= 8 && !doPasswordsMatch(regFields.password,regFields.confirm)) setRegFields({...regFields,p2type:0,p2isVisible:true,p2message:"Passwords do not match.",p1type:0,p1isVisible:true,p1message:"Passwords do not match."});
            }
        } else {
            if (regFields.confirm.length !== regFields.password.length) {
                setRegFields({...regFields,p2type:0,p2isVisible:true, p2message:"Passwords do not match."});
            } else {
                if (regFields.confirm.length < 8) {
                    setRegFields({...regFields,p2type:0,p2isVisible:true, p2message:"Password must be at least 8 characters."});
                } else {
                    if (!doPasswordsMatch(regFields.password,regFields.confirm)) {
                        setRegFields({...regFields,p2type:0,p2isVisible:true, p2message:"Passwords do not match."});
                    } else {
                        setRegFields({...regFields,p2type:1,p2isVisible:true,p2message:"Passwords match.",p1type:1,p1isVisible:true,p1message:"Passwords is good."});
                    }
                }
            }
        }
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (regFields.password.length < 8 || !doPasswordsMatch(regFields.password,regFields.confirm)) {
            dispatch(setErrors(["Your passwords do not match or meet the minimum length requirments."]));
            return;
        }
        const params = {
            token,
            data:{password}
        };
        dispatch(fetchPasswordUpdateAsync(params));
        
    };
    const spanStyles = {
        fontSize:"0.7em",
        color:"#757575"  
    };
    return (
        <Fragment>
            <LoginBoxPage>
                <LoginBoxStyles height="300">
                <SectionTitle icon="fa fa-sign-in" size="small">{title}</SectionTitle>
                <Form onSubmit={handleSubmit}>
                    <LoginBoxRow>
                            <span style={spanStyles}>Welcome!</span><br />
                            <InputRegistrationEmail type="email" name="email" width="240" placeholder="Email" value={email} readOnly={true}/>
                    </LoginBoxRow>
                    <LoginBoxRow>
                            <Input type="password" name="password" width="240" placeholder="Password" value={password} onChange={handleChange} onBlur={passwordMatch} /> <PasswordIcon title={p1message} type={p1type} isVisible={p1isVisible} />
                    </LoginBoxRow>
                    <LoginBoxRow>
                            <Input type="password" name="confirm" width="240" placeholder="Confirm Password" value={confirm} onChange={handleChange} onBlur={passwordMatch} /> <PasswordIcon title={p2message} type={p2type} isVisible={p2isVisible} />
                    </LoginBoxRow>
                    <LoginBoxRow>
                        <FormButton type="submit">
                            <Icon icon="fa fa-sign-in" /><ButtonText text={buttonLabel} />
                        </FormButton>
                    </LoginBoxRow>
                </Form>
                </LoginBoxStyles>
            </LoginBoxPage>
            <Loading isVisible={userIsLoading} />
        </Fragment>
    );
};
export default RegistrationPanel;