import { Fragment } from "react";
import { FilesContainerStyles } from "./files-container.styles";
import FileBlock from "../file-block/file-block.component";

const FilesContainer = ({files=[],label}) => {
    return (
        <Fragment>
            {label}<br />
            <FilesContainerStyles>
                {
                    ((files.length && files.map((file,idx)=><FileBlock key={idx} fileName={file.filename} url={file.encid} />)) || (<p>No files available.</p>))
                }
            </FilesContainerStyles>
        </Fragment>
    );
};
export default FilesContainer;