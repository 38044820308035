import { Fragment } from "react";
import { DetailsDataElementLabel, DetailsDataElementData } from "./details-data-element.styles";

const DetailsDataElement = ({label,data,dataArray=[]}) => {
    return (
        <Fragment>
            <DetailsDataElementLabel>{label}</DetailsDataElementLabel>
            <DetailsDataElementData>
                {
                    ((dataArray.length && dataArray.map((ele,idx)=><Fragment key={idx}><span>{ele}</span><br /></Fragment>)) || data)
                }
            </DetailsDataElementData>
        </Fragment>
    );
};
export default DetailsDataElement;