import styled, {css} from "styled-components";

const ColOpen = css`
    @media(min-width:601px) {
        width:66.66666%;
    }
`;
const ColClosed = css`
    @media(min-width:601px) {
        width:33.33333%;
    }
`;

export const ColTwoThirdStyles = styled.div`
    padding:0 8px;
    transition: all 1s;
    position:relative;
    width:100%;
    float:left;

    ${({isOpen})=>isOpen ? ColOpen : ColClosed}
`;