import { PROJECT_LIST_ACTION_TYPES } from "./project-list.types";

const INITIAL_STATE = {
  projectList: [],
  isLoading: false,
  pageCount: 0,
  error: null,
  projectDetails: {},
  detailsIsLoading: false,
  detailsError: null,
  queryOptions: {
    itemsPerPage: 100,
    limitStart: 0,
    limitCount: 15,
    pageNum: 1,
  },
  downloadFileUrl: "",
  downloadFileError: null,
  fileUploadData: [],
  fileUploadDataError: null,
  fileUploadInitiated: false,
  fileUploadComplete: false,
  fileUploadError: null,
  fileData: [],
  isFileUploading: false,
  newJobComplete: false,
};

export const projectListReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case PROJECT_LIST_ACTION_TYPES.CLEAR_ALL_ERRORS:
      return {
        ...state,
        error: null,
        detailsError: null,
        downloadFileError: null,
        fileUploadDataError: null,
        fileUploadError: null,
      };
    case PROJECT_LIST_ACTION_TYPES.SET_PROJECT_LIST:
      return {
        ...state,
        projectList: payload,
      };
    case PROJECT_LIST_ACTION_TYPES.SET_FILE_DATA:
      return {
        ...state,
        fileData: payload,
      };
    case PROJECT_LIST_ACTION_TYPES.CLEAR_NEW_JOB_COMPLETE:
      return {
        ...state,
        newJobComplete: false,
      };
    case PROJECT_LIST_ACTION_TYPES.REFRESH_DATA:
      return {
        ...INITIAL_STATE,
        projectList: [...state.projectList],
        projectDetails: { ...state.projectDetails },
        newJobComplete: state.newJobComplete,
        queryOptions: { ...state.queryOptions },
      };
    case PROJECT_LIST_ACTION_TYPES.SET_PAGE_COUNT:
      return {
        ...state,
        pageCount: payload,
      };
    case PROJECT_LIST_ACTION_TYPES.SET_QUERY_OPTIONS:
      return {
        ...state,
        queryOptions: payload,
      };
    case PROJECT_LIST_ACTION_TYPES.SET_QUERY_OPTIONS_PAGE_NUM:
      return {
        ...state,
        queryOptions: { ...state.queryOptions, pageNum: payload },
      };
    case PROJECT_LIST_ACTION_TYPES.SET_QUERY_OPTIONS_LIMIT_START:
      return {
        ...state,
        queryOptions: { ...state.queryOptions, limitStart: payload },
      };
    case PROJECT_LIST_ACTION_TYPES.RESET_QUERY_OPTIONS:
      return {
        ...state,
        queryOptions: { ...INITIAL_STATE.queryOptions },
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_PROJECTS_START:
    case PROJECT_LIST_ACTION_TYPES.FETCH_FILE_UPLOAD_DATA_START:
      return {
        ...state,
        isLoading: true,
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_NEW_JOB_START:
      return {
        ...state,
        isLoading: true,
        fileUploadComplete: false,
      };
    case PROJECT_LIST_ACTION_TYPES.SET_FILE_UPLOAD_COMPLETE:
      return {
        ...state,
        fileUploadComplete: payload,
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_UPLOAD_START:
      return {
        ...state,
        fileUploadInitiated: false,
        isFileUploading: true,
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_NEW_JOB_SUCCESS:
      return {
        ...state,
        isLoading: false,
        newJobComplete: true,
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_NEW_JOB_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_UPLOAD_SUCCESS:
      return {
        ...state,
        isFileUploading: false,
        fileUploadComplete: true,
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        projectList: payload,
        isLoading: false,
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_FILE_UPLOAD_DATA_SUCCESS:
      return {
        ...state,
        fileUploadData: payload,
        isLoading: false,
        fileUploadInitiated: true,
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_PROJECTS_FAILED:
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_UPLOAD_FAILED:
      return {
        ...state,
        fileUploadError: payload,
        isFileUploading: false,
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_FILE_UPLOAD_DATA_FAILED:
      return {
        ...state,
        fileUploadDataError: payload,
        isLoading: false,
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_DOWNLOAD_FILE_URL:
      return {
        ...state,
        downloadFileUrl: payload,
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_DOWNLOAD_FILE_URL_ERROR:
      return {
        ...state,
        downloadFileError: payload,
      };
    case PROJECT_LIST_ACTION_TYPES.RESET_DOWNLOAD_FILE_URL:
      return {
        ...state,
        downloadFileUrl: "",
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_PROJECT_DETAILS_START:
      return {
        ...state,
        detailsIsLoading: true,
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_PROJECT_DETAILS_SUCCESS:
      return {
        ...state,
        projectDetails: payload,
        detailsIsLoading: false,
      };
    case PROJECT_LIST_ACTION_TYPES.FETCH_PROJECT_DETAILS_FAILED:
      return {
        ...state,
        detailsError: payload,
        detailsIsLoading: false,
      };
    default:
      return state;
  }
};
