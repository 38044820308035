import styled from "styled-components";

export const InputFileStyles = styled.input`

    &::file-selector-button {
        font-size:1.2em;
        color:#FFF;
        background:#2196F3;
        padding:6px 12px;
        border:1px solid #CCC;
        border-radius:5px;
    }
`;