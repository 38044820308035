import { createSelector } from "reselect";

export const selectAIPPropsReducer = state => state.aip;

export const selectAIPProps = createSelector(
  [selectAIPPropsReducer],
  slice => slice.AIPProps
);

export const selectAIPPanels = createSelector(
  [selectAIPPropsReducer],
  slice => slice.AIPProps.panels
);
