import styled from "styled-components";

export const DetailsH2Styles = styled.h2`
    width:100%;
    border-bottom:1px solid #CCC;
    text-align:right;
    color:#757575;
    font-size:1.2em;
    margin:0 0 12px 0;
    & span {
        color:#2196F3;
    }
`;