import { SERVICE_OPTIONS_TYPES } from "./service-options.types";
const INITIAL_STATE = {
  options: { services: [], languages: [], formats: [] },
  error: null,
  isLoading: false,
};

export const serviceOptionsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case SERVICE_OPTIONS_TYPES.FETCH_SERVICE_OPTIONS_START:
      return {
        ...state,
        isLoading: true,
      };
    case SERVICE_OPTIONS_TYPES.FETCH_SERVICE_OPTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        options: payload,
      };
    case SERVICE_OPTIONS_TYPES.FETCH_SERVICE_OPTIONS_FAILED:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    case SERVICE_OPTIONS_TYPES.CLEAR_SERVICE_OPTIONS_ERROR:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
