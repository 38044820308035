import { InputFileListStyles, InputFileListWrapper } from "./input-file-list.styles";
import Table from "../table/table.component";

const InputFileList = ({tableData}) => {
    return (
        <InputFileListWrapper>
                    <InputFileListStyles>
                        <Table tableData={tableData} />
                    </InputFileListStyles>
        </InputFileListWrapper>
    );
};
export default InputFileList;