import { useState, useEffect, Fragment, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LoginBoxPage, LoginBoxStyles, LoginBoxRow } from "./login-box.styles";
import SectionTitle from "../section-title/section-title.component";
import Form from "../form/form.component";
import Input from "../input/input.component";
import FormButton from "../form-button/form-button.component";
import Icon from "../icon/icon.component";
import ButtonText from "../button-text/button-text.component";
import { getToken, selectEncLogin, selectCheckEncoded, selectEnc } from "../../store/user/user.selector";
import { tokenExpired } from "../../utils/dates/date.utils";
import { loginAsync, clearUserError, setEncLogin, setCheckEncoded, setEnc } from "../../store/user/user.actions";
import { selectUserError, selectUserTokenExpires, selectUserIsLoading } from "../../store/user/user.selector";
import { selectErrors, selectMessages } from "../../store/messages/messages.selector";
import { setErrors, setMessagesTimeout } from "../../store/messages/messages.actions";
import Loading from "../loading/loading.component";

const defaultLoginValues = {
    email: '',
    password: ''
};

const LoginBox = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = useSelector(getToken);
    const expires = useSelector(selectUserTokenExpires);
    const userError = useSelector(selectUserError);
    const errorMessages = useSelector(selectErrors);
    const messages = useSelector(selectMessages);
    const userIsLoading = useSelector(selectUserIsLoading);
    const isEncryptedLogin = useSelector(selectEncLogin);
    const checkEncLogin = useSelector(selectCheckEncoded);
    const encdata = useSelector(selectEnc);
    const [formFields,setFormFields] = useState(defaultLoginValues);
    const { email, password } = formFields;
    const useQuery = () => {
        const { search } = useLocation();
        return useMemo(()=> new URLSearchParams(search),[search]);
    };
    const query = useQuery();

    const resetFormFields = () => {
        setFormFields(defaultLoginValues);
    }
    useEffect(()=>{
        if (!checkEncLogin) return;
        const enc = query.get("enc");
        if (enc) dispatch(setEnc(enc));
        dispatch(setCheckEncoded(false));
    },[checkEncLogin, dispatch, query]);
    useEffect(()=>{
        if (!isEncryptedLogin) return;
        dispatch(setEncLogin(false));
        dispatch(loginAsync({email:"",password:"",encdata}));
    },[isEncryptedLogin, dispatch]);
    /*
    useEffect(()=> {
        if (userError == null) return;
        dispatch(setErrors([...errorMessages,userError.message]));
        dispatch(clearUserError());
    }, [userError]);
    */
    useEffect(()=> {
        if (token == null || expires == null) return;
        if (!tokenExpired(expires)) { 
            resetFormFields();
            dispatch(setMessagesTimeout([...messages,"Welcome!"],5000));
            navigate("/");  
        }
    },[token,expires]);
    const handleSubmit = async (event) => {
        event.preventDefault();
        dispatch(loginAsync({email, password}));
    };
    const handleChange = (event) =>{
        const { name, value } = event.target;
        setFormFields({...formFields, [name]: value});
    };
    return (
        <Fragment>
            <LoginBoxPage>
                <LoginBoxStyles>
                    <SectionTitle icon="fa fa-sign-in" size="small">Login</SectionTitle>
                        <Form onSubmit={handleSubmit}>
                        <LoginBoxRow>
                                <Input type="email" name="email" width="240" placeholder="Email" value={email} onChange={handleChange} />
                        </LoginBoxRow>
                        <LoginBoxRow>
                                <Input type="password" name="password" width="240" placeholder="Password" value={password} onChange={handleChange} />
                        </LoginBoxRow>
                        <LoginBoxRow>
                            <FormButton type="submit">
                                <Icon icon="fa fa-sign-in" /><ButtonText text="Login" />
                            </FormButton>
                        </LoginBoxRow>
                        </Form>
                </LoginBoxStyles>
            </LoginBoxPage>
            <Loading isVisible={userIsLoading} />
        </Fragment>
    );
};
export default LoginBox;