export const PROJECT_LIST_ACTION_TYPES = {
  SET_PROJECT_LIST: "projectList/SET_PROJECT_LIST",
  FETCH_PROJECTS_START: "projectList/SET_PROJECTS_START",
  FETCH_PROJECTS_SUCCESS: "projectList/SET_PROJECTS_SUCCESS",
  FETCH_PROJECTS_FAILED: "projectList/SET_PROJECTS_FAILED",
  FETCH_PROJECT_DETAILS_START: "projectList/FETCH_PROJECT_DETAILS_START",
  FETCH_PROJECT_DETAILS_SUCCESS: "projectList/FETCH_PROJECT_DETAILS_SUCCESS",
  FETCH_PROJECT_DETAILS_FAILED: "projectList/FETCH_PROJECT_DETAILS_FAILED",
  SET_PAGE_COUNT: "projectList/SET_PAGE_COUNT",
  SET_QUERY_OPTIONS: "projectList/SET_QUERY_OPTIONS",
  SET_QUERY_OPTIONS_PAGE_NUM: "projectList/SET_QUERY_OPTIONS_PAGE_NUM",
  SET_QUERY_OPTIONS_LIMIT_START: "projectlist/SET_QUERY_OPTIONS_LIMIT_START",
  RESET_QUERY_OPTIONS: "projectlist/RESET_QUERY_OPTIONS",
  FETCH_DOWNLOAD_FILE_URL: "projectlist/FETCH_DOWNLOAD_FILE_URL",
  FETCH_DOWNLOAD_FILE_URL_ERROR: "projectlsit/FETCH_DOWNLOAD_FILE_URL_ERROR",
  RESET_DOWNLOAD_FILE_URL: "projectlist/RESET_DOWNLOAD_FILE_URL",
  FETCH_FILE_UPLOAD_DATA_START: "projectlist/FETCH_FILE_UPLOAD_DATA_START",
  FETCH_FILE_UPLOAD_DATA_SUCCESS: "projectlist/FETCH_FILE_UPLOAD_DATA_SUCCESS",
  FETCH_FILE_UPLOAD_DATA_FAILED: "projectlist/FETCH_FILE_UPLOAD_DATA_FAILED",
  FETCH_UPLOAD_START: "projectlist/FETCH_UPLOAD_START",
  FETCH_UPLOAD_SUCCESS: "projectlist/FETCH_UPLOAD_SUCCESS",
  FETCH_UPLOAD_FAILED: "projectlist/FETCH_UPLOAD_FAILED",
  FETCH_NEW_JOB_START: "projectlist/FETCH_NEW_JOB_START",
  FETCH_NEW_JOB_SUCCESS: "projectlist/FETCH_NEW_JOB_SUCCESS",
  FETCH_NEW_JOB_FAILED: "projectlist/FETCH_NEW_JOB_FAILED",
  SET_FILE_DATA: "projectlist/SET_FILE_DATA",
  REFRESH_DATA: "projectlist/REFRESH_DATA",
  CLEAR_NEW_JOB_COMPLETE: "projectlist/CLEAR_NEW_JOB_COMPLETE",
  SET_FILE_UPLOAD_COMPLETE: "projectlist/SET_FILE_UPLOAD_COMPLETE",
  CLEAR_ALL_ERRORS: "projectlist/CLEAR_ALL_ERRORS",
};
