import styled from "styled-components";

export const InnerContainerStyles = styled.div`
    position:relative;
    padding:0.01em 16px;
    &:before,&:after {
        content:"",
        display:table;
        clear:both;
    }
`;