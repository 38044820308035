import { PROJECT_FORM_FIELDS_ACTION_TYPES } from "./project-form-fields.types";

const INITIAL_STATE = {
  projectFormFields: {
    name: "",
    manager: "",
    description: "",
    startDate: "",
    deadline: "",
    servicesData: [],
    files: [],
    rawFiles: [],
    encid: 0,
    readonly: false,
  },
};

export const projectFormFieldsReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case PROJECT_FORM_FIELDS_ACTION_TYPES.SET_PROJECT_FORM_FIELDS:
      return {
        ...state,
        projectFormFields: payload,
      };
    case PROJECT_FORM_FIELDS_ACTION_TYPES.ADD_SERVICES_DATA_ROW:
      return {
        ...state,
        projectFormFields: {
          ...state.projectFormFields,
          servicesData: [...state.projectFormFields.servicesData, payload],
        },
      };
    case PROJECT_FORM_FIELDS_ACTION_TYPES.ADD_FILES:
      return {
        ...state,
        projectFormFields: {
          ...state.projectFormFields,
          files: [...state.projectFormFields.files, ...payload],
        },
      };
    case PROJECT_FORM_FIELDS_ACTION_TYPES.ADD_RAW:
      return {
        ...state,
        projectFormFields: {
          ...state.projectFormFields,
          rawFiles: [...state.projectFormFields.rawFiles, ...payload],
        },
      };
    case PROJECT_FORM_FIELDS_ACTION_TYPES.CLEAR_FIELDS:
      return {
        ...state,
        projectFormFields: { ...INITIAL_STATE.projectFormFields },
      };
    case PROJECT_FORM_FIELDS_ACTION_TYPES.SET_FIELD:
      return {
        ...state,
        projectFormFields: {
          ...state.projectFormFields,
          [payload.name]: payload.value,
        },
      };
    default:
      return state;
  }
};
