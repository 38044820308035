import { Fragment } from "react";
import { useSelector } from "react-redux";
import { TableStyles} from "../table/table.styles";
import TableHeader from "../table-header/table-header.component";
import TableClickRow from "../table-click-row/table-click-row.component";
import { selectProjectListMap } from "../../store/project-list/project-list.selector";
import SelectPage from "../select-page/select-page.component";

const ProjectListComponent = () => {
    const projectList = useSelector(selectProjectListMap);
    const {header,rows, widths, ids} = projectList;
    return (
        <Fragment>
            <TableStyles>
                <thead>
                <tr>
                    {
                        header && header.map((ele,idx)=><TableHeader key={`tableheader${idx}`} value={ele} width={`${widths[idx]}`} />)
                    }
                </tr>
                </thead>
                <tbody>
                {
                    rows && rows.map((row,idx)=><TableClickRow encid={ids[idx]} key={`tablerow${idx}`} row={row} />)
                }
                </tbody>
            </TableStyles>
            <SelectPage />
        </Fragment>
    );
};
export default ProjectListComponent;