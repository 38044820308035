import { Fragment } from "react";
import { LoadingContainer } from "./loading.styles";
import Cover from "../cover/cover.component";

const Loading = ({isVisible}) => {
    return (
        <Fragment>
            <LoadingContainer isVisible={isVisible} /><Cover isVisible={isVisible} />
        </Fragment>
    );
};
export default Loading;