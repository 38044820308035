import { createSelector } from "reselect";
import { dateStringFromUnix } from "../../utils/dates/date.utils";
import FileDownloadIcon from "../../components/file-download-icon/file-download-icon.component";

const selectProjectsReducer = state => state.pl;

export const selectProjectList = createSelector(
  [selectProjectsReducer],
  slice => slice.projectList
);
export const selectNewJobComplete = createSelector(
  [selectProjectsReducer],
  slice => slice.newJobComplete
);
export const selectProjectIsLoading = createSelector(
  [selectProjectsReducer],
  slice => slice.isLoading
);

export const selectDownloadFileUrl = createSelector(
  [selectProjectsReducer],
  slice => slice.downloadFileUrl
);
export const selectIsFileUploading = createSelector(
  [selectProjectsReducer],
  slice => slice.isFileUploading
);
export const selectDetailsIsLoading = createSelector(
  [selectProjectsReducer],
  slice => slice.detailsIsLoading
);
export const selectFileData = createSelector(
  [selectProjectsReducer],
  slice => slice.fileData
);
export const selectFileUploadInitiated = createSelector(
  [selectProjectsReducer],
  slice => slice.fileUploadInitiated
);
export const selectFileUploadData = createSelector(
  [selectProjectsReducer],
  slice => slice.fileUploadData
);
export const selectFileUploadComplete = createSelector(
  [selectProjectsReducer],
  slice => slice.fileUploadComplete
);
export const selectProjectDetails = createSelector(
  [selectProjectsReducer],
  slice => slice.projectDetails
);
export const selectDetailsError = createSelector(
  [selectProjectsReducer],
  slice => slice.detailsError
);
export const selectPageCount = createSelector(
  [selectProjectsReducer],
  slice => slice.pageCount
);
export const selectQueryOptions = createSelector(
  [selectProjectsReducer],
  slice => slice.queryOptions
);
export const selectQueryOptionsLimitCount = createSelector(
  [selectProjectsReducer],
  slice => slice?.queryOptions?.limitCount
);
export const selectProjectDetailsFileMap = createSelector(
  [selectProjectsReducer],
  slice => {
    const widths = ["32%", "32%", "32%", "4%"];
    const header = ["Services", "Languages", "Files", ""];
    const rows = slice?.projectDetails?.files
      ? slice.projectDetails.files.map(file => {
          return [
            file.services.map(svc => svc.service),
            file.languages.map(lang => lang.languageName),
            file.filename,
            <FileDownloadIcon encid={file.encid} downloadType="source" />,
          ];
        })
      : [];
    return { widths, header, rows };
  }
);
export const selectProjectListMap = createSelector(
  [selectProjectsReducer],
  slice => {
    const widths = ["14%", "45%", "18%", "20%"];
    const header = [
      "Project #",
      "Project Title",
      "Project Status",
      "Start Date",
    ];
    const rows = slice?.projectList
      ? slice.projectList.map(row => {
          return [
            row.jobNumber,
            row.jobName,
            row.status,
            dateStringFromUnix(row.jobStart),
          ];
        })
      : [];
    const ids = slice?.projectList
      ? slice.projectList.map(row => row.jobID)
      : [];
    return { widths, header, rows, ids };
  }
);
export const selectAllProjectListErrors = createSelector(
  [selectProjectsReducer],
  slice => ({
    error: slice.error,
    detailsError: slice.detailsError,
    downloadFileError: slice.downloadFileError,
    fileUploadDataError: slice.fileUploadDataError,
    fileUploadError: slice.fileUploadError,
  })
);
