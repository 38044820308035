import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { FileBlockStyles } from "./file-block.styles";
import { selectProjectDetails } from "../../store/project-list/project-list.selector";
import { fetchFileDownloadUrlAsync, resetDownloadFileUrl } from "../../store/project-list/project-list.actions";
import { selectDownloadFileUrl } from "../../store/project-list/project-list.selector";
import { getToken } from "../../store/user/user.selector";
import { triggerDownload } from "../../utils/download/download.utils";



const FileBlock = ({fileName,url=""}) => {
    const dispatch = useDispatch();
    const projectDetails = useSelector(selectProjectDetails);
    const downloadFileUrl = useSelector(selectDownloadFileUrl);
    const token = useSelector(getToken);

    useEffect(()=> {
        if (downloadFileUrl === "") return;
        triggerDownload(downloadFileUrl);
        dispatch(resetDownloadFileUrl());
    },[downloadFileUrl,dispatch]);

    const handleFileDownload = (event) => {
        const encid = event.target.dataset.url;

       const data = {
            file:encid,
            slug:projectDetails.slug,
            job:projectDetails.details.jobNumber,
            type:"job-delivered-files"
       }
       
       dispatch(fetchFileDownloadUrlAsync({data,token}));
    };
    return (  
        <FileBlockStyles data-url={url} onClick={handleFileDownload}>{fileName}</FileBlockStyles>
    );
};
export default FileBlock;