const extensions = [
  "mp3",
  "docx",
  "doc",
  "txt",
  "pdf",
  "wav",
  "mp4",
  "avi",
  "ppt",
  "xls",
  "xlsx",
  "zip",
  "srt",
  "mov",
  "scc",
  "vtt",
  "rar",
  "vsdx",
  "vsd",
  "psd",
  "ai",
  "indd",
  "idml",
  "pptx",
  "story",
  "png",
  "jpeg",
  "jpg",
];

export const filterFiles = filelist => {
  return filelist.filter(ele =>
    extensions.includes(ele.filename.split(".").pop())
  );
};
export const checkExtension = ext => {
  return extensions.includes(ext);
};
