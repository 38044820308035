import { UPLOAD_ONLY_ACTION_TYPES } from "./upload-only.types";

const INITIAL_STATE = {
  flags: {
    authInit: true,
    authStarted: false,
    authSuccess: false,
    authFailed: false,
    processStarted: false,
    processCompleted: false,
  },
  token: null,
  projectData: {
    projectNumber: "",
    projectName: "",
  },
  authError: null,
  processUploadsError: null,
  uploadFileData: [],
};

export const uploadOnlyReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_ONLY_ACTION_TYPES.RESET_STATE:
      return {
        ...INITIAL_STATE,
        flags: {
          ...INITIAL_STATE.flags,
          authInit: false,
        },
        token: state.token,
        projectData: { ...state.projectData },
      };
    case UPLOAD_ONLY_ACTION_TYPES.PROCESS_UPLOADS_START:
      return {
        ...state,
        flags: { ...state.flags, processStarted: true },
      };
    case UPLOAD_ONLY_ACTION_TYPES.PROCESS_UPLOADS_FAILED:
      return {
        ...state,
        flags: { ...state.flags, processStarted: false },
        processUploadsError: payload,
      };
    case UPLOAD_ONLY_ACTION_TYPES.CLEAR_PROCESS_COMPLETE:
      return {
        ...state,
        flags: {
          ...state.flags,
          processCompleted: false,
        },
      };
    case UPLOAD_ONLY_ACTION_TYPES.PROCESS_UPLOADS_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          processStarted: false,
          processCompleted: true,
        },
      };
    case UPLOAD_ONLY_ACTION_TYPES.SET_FILE_DATA:
      return {
        ...state,
        uploadFileData: payload,
      };
    case UPLOAD_ONLY_ACTION_TYPES.START_AUTH:
      return {
        ...state,
        flags: {
          ...state.flags,
          authInit: false,
          authStarted: true,
        },
      };
    case UPLOAD_ONLY_ACTION_TYPES.AUTH_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          authStarted: false,
          authSuccess: true,
        },
      };
    case UPLOAD_ONLY_ACTION_TYPES.AUTH_FAILED:
      return {
        ...state,
        flags: {
          ...state.flags,
          authStarted: false,
          authFailed: true,
        },
        authError: payload,
      };
    case UPLOAD_ONLY_ACTION_TYPES.AUTH_SET_TOKEN:
      return {
        ...state,
        token: payload,
      };
    case UPLOAD_ONLY_ACTION_TYPES.SET_PROJECT_DATA:
      return {
        ...state,
        projectData: payload,
      };
    default:
      return state;
  }
};
