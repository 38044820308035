import { useSelector } from 'react-redux';
import { ColTwoThirdStyles } from './col-two-third.styles';
import { selectAIPProps } from '../../store/aip/aip.selector';

const ColTwoThird = ({children}) => {
    const AIPProps = useSelector(selectAIPProps);
    if (AIPProps == null) return;
    const {colTwoThird:{isOpen}} = AIPProps;
    return (
        <ColTwoThirdStyles isOpen={isOpen}>{children}</ColTwoThirdStyles>
    );
};
export default ColTwoThird;