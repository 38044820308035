import { createAction } from "../../utils/reducer/reducer.utils";
import { AIP_ACTION_TYPES } from "./aip.types";

export const setAIPProps = props =>
  createAction(AIP_ACTION_TYPES.SET_AIP_PROPS, props);

export const flipOpeners = () => createAction(AIP_ACTION_TYPES.FLIP_OPENERS);

export const setUploadProgressModalVisible = vis =>
  createAction(AIP_ACTION_TYPES.SET_UPLOAD_PROGRESS_MODAL_VISIBLE, vis);
