import styled from "styled-components";

export const FileBlockStyles = styled.div`
    width:100%;
    padding:12px 0;
    text-align:center;
    color: #757575;
    border-bottom:1px solid #CCC;
    font-size:1.2em;
    cursor:pointer;

    &:last-child {
        border-bottom:0;
    }

    & a {
        color:#FFF;
        text-decoration:none;
    }
`;

/*
export const FileBlockStyles = styled.div`
    width:100%;
    padding:12px 0;
    text-align:center;
    background: rgb(33,150,243);
    background: linear-gradient(180deg, rgba(33,150,243,1) 0%, rgba(33,164,243,1) 50%, rgba(33,192,243,1) 100%);
    color: #FFF;
    border-bottom:1px solid #CCC;
    font-size:1.2em;
    cursor:pointer;

    &:last-child {
        border-bottom:0;
    }

    & a {
        color:#FFF;
        text-decoration:none;
    }
`;
*/