import { combineReducers } from "redux";
import { userReducer } from "./user/user.reducer";
import { AIPReducer } from "./aip/aip.reducer";
import { modalReducer } from "./modal/modal.reducer";
import { fileListModalReducer } from "./file-list-modal/file-list-modal.reducer";
import { projectListReducer } from "./project-list/project-list.reducer";
import { projectFormServicesReducer } from "./project-form-services/project-form-services.reducer";
import { messagesReducer } from "./messages/messages.reducer";
import { projectFormFieldsReducer } from "./project-form-fields/project-form-fields.reducer";
import { serviceOptionsReducer } from "./service-options/service-options.reducer";
import { uploadOnlyReducer } from "./upload-only/upload-only.reducer";

export const rootReducer = combineReducers({
  user: userReducer,
  aip: AIPReducer,
  modal: modalReducer,
  flm: fileListModalReducer,
  pl: projectListReducer,
  pfs: projectFormServicesReducer,
  mes: messagesReducer,
  pff: projectFormFieldsReducer,
  so: serviceOptionsReducer,
  upo: uploadOnlyReducer,
});
