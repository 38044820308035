import { createAction } from "../../utils/reducer/reducer.utils";
import { UPLOAD_ONLY_ACTION_TYPES } from "./upload-only.types";
import { AWSRequest } from "../../utils/AWS/aws.utils";

export const setFileUploadData = data =>
  createAction(UPLOAD_ONLY_ACTION_TYPES.SET_FILE_DATA, data);

export const setUOToken = token =>
  createAction(UPLOAD_ONLY_ACTION_TYPES.AUTH_SET_TOKEN, token);

export const setUOProjectData = data =>
  createAction(UPLOAD_ONLY_ACTION_TYPES.SET_PROJECT_DATA, data);

export const fetchUOAuthStart = () =>
  createAction(UPLOAD_ONLY_ACTION_TYPES.START_AUTH);

export const fetchUOAuthError = error =>
  createAction(UPLOAD_ONLY_ACTION_TYPES.AUTH_FAILED, error);

export const fetchUOAuthSuccess = () =>
  createAction(UPLOAD_ONLY_ACTION_TYPES.AUTH_SUCCESS);

export const fetchUOAuthAsync = params => async dispatch => {
  dispatch(fetchUOAuthStart());
  try {
    const response = await AWSRequest(
      params.data,
      params.endpoint,
      params.token
    );
    switch (response.statusCode) {
      case 200:
        dispatch(setUOToken(response.token));
        dispatch(
          setUOProjectData({
            projectNumber: response.ponum,
            projectName: response.jobname,
          })
        );
        dispatch(fetchUOAuthSuccess());
        break;
      case 401:
        dispatch(
          fetchUOAuthError({
            message: response?.error || "Not authorized.",
            name: "NOTAUTHORIZED",
          })
        );
        break;
      case 500:
      default:
        dispatch(
          fetchUOAuthError({
            message: response?.error || "Unknown error.",
            name: "FATALERROR",
          })
        );
        break;
    }
  } catch (error) {
    dispatch(
      fetchUOAuthError({
        message:
          "An unknow error occurred while fetching the credentials for this transaction. Please contact your project manager for a new upload link.",
        name: "UNKNOWAUTHUPLOADONLY",
      })
    );
  }
};

export const resetState = () =>
  createAction(UPLOAD_ONLY_ACTION_TYPES.RESET_STATE);

export const clearProcessComplete = () =>
  createAction(UPLOAD_ONLY_ACTION_TYPES.CLEAR_PROCESS_COMPLETE);

export const processUploadsStart = () =>
  createAction(UPLOAD_ONLY_ACTION_TYPES.PROCESS_UPLOADS_START);

export const processUploadsError = error =>
  createAction(UPLOAD_ONLY_ACTION_TYPES.PROCESS_UPLOADS_FAILED, error);

export const processUploadsSuccess = () =>
  createAction(UPLOAD_ONLY_ACTION_TYPES.PROCESS_UPLOADS_SUCCESS);

export const processUploadsAsync = params => async dispatch => {
  dispatch(processUploadsStart());
  try {
    const response = await AWSRequest(
      params.data,
      "https://clients-endpoint.worldtranslationcenter.com/v1/vendor-process-files",
      params.token
    );
    switch (response.statusCode) {
      case 200:
        if (response.count !== params.data.data.length) {
          dispatch(
            processUploadsError({
              message:
                "An error occured setting file data, not all files could be processed. Please contact your project manager.",
              name: "UNKNOWNERROR",
            })
          );
        } else {
          dispatch(processUploadsSuccess());
        }
        break;
      case 500:
      default:
        dispatch(
          processUploadsError({
            message:
              "An error occured setting file data. Please contact your project manager.",
            name: "UNKNOWNERROR",
          })
        );
        break;
    }
  } catch (error) {
    dispatch(
      processUploadsError({
        message:
          "An error occured setting file data. Please contact your project manager.",
        name: "UNKNOWNERROR",
      })
    );
  }
};
