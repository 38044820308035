import { useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Modal from "../modal/modal.component";
import Form from "../form/form.component";
import SectionTitle from "../section-title/section-title.component";
import CheckboxSection from "../checkbox-section/checkbox-section.component";
import RowInner from "../row-inner/row-inner.component";
import InputSelect from "../input-select/input-select.component";
import InputFile from "../input-file/input-file.component";
import Textarea from "../textarea/textarea.component";
import FormButton from "../form-button/form-button.component";
import Icon from "../icon/icon.component";
import ButtonText from "../button-text/button-text.component";
import InputFileList from "../input-file-list/input-file-list.component";
import ColHalf from "../col-half/col-half.component";
import { selectAIPProps } from "../../store/aip/aip.selector";
import { setAIPProps } from "../../store/aip/aip.actions";
import { setModalFields } from "../../store/modal/modal.actions";
import { selectModalFields } from "../../store/modal/modal.selector";
import { selectCurrentFileList } from "../../store/file-list-modal/file-list-modal.selector";
import { setCurrentFileList } from "../../store/file-list-modal/file-list-modal.actions";
import { setProjectFormServices } from "../../store/project-form-services/project-form-services.actions";
import { selectProjectFormServices } from "../../store/project-form-services/project-form-services.selector";
import { selectServiceOptions } from "../../store/service-options/service-options.selector";
import { checkExtension } from "../../utils/upload/upload.utils";
import { setErrors } from "../../store/messages/messages.actions";

const formatData = (input) => {
    const sections = [
        "services",
        "dest",
        "delivery"
    ];
    const out = {};
    for (const key in input) {
        if (key.indexOf("_") < 0) {
            switch(key) {
                case "files":
                case "rawfiles":
                    out[key] = input[key];
                    break;
                case "sourcelanguage":
                    out[key] = parseInt(input[key].value);
                    break;
                default:
                    out[key] = input[key].value;
                    break;
            }
        } else {
            const [section] = key.split("_");
            if (!sections.includes(section)) {
                out[key] = input[key].value;
            } else {
                if (!Array.isArray(out[section])) out[section] = [];
                if (input[key].isChecked) out[section].push(parseInt(input[key].value));
            }
        }
    }
    return out;
};
const buildTableData = (data,services,languages,formats) => {
    const header = [
        "Services",
        "Source Language",
        "Dest Languages",
        "Delivery Formats",
        "Files",
        "Notes"
    ];
    const widths = [
        "15%",
        "15%",
        "15%",
        "15%",
        "15%",
        "25%"
    ];
    const row = [
        data.services ? data.services.map(serviceID => ucFirst(services.filter(svc => svc.serviceID === serviceID)[0].service)).map((name,idx)=><div key={idx}>{name}</div>) : [],
        ucFirst(languages.filter(lang => lang.languageID === data.sourcelanguage)[0].languageName),
        data.dest ? data.dest.map(languageID => ucFirst(languages.filter(lang => lang.languageID === languageID)[0].languageName)).map((name,idx) => <div key={idx}>{name}</div>) : [],
        data.delivery ? data.delivery.map(formatID => ucFirst(formats.filter(fmt => fmt.formatID === formatID)[0].format)).map((name,idx) => <div key={idx}>{name}</div>) : [],
        data.files ? data.files.map(file=>file.name).map((fname,idx)=><div key={idx}>{fname}</div>) : [],
        data.notes ? data.notes : ""
    ];
    return {header,row,widths};
};
const ucFirst = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
}
const ServiceModal = () => {
    const dispatch = useDispatch();

    const modalFields = useSelector(selectModalFields);
    const AIPProps = useSelector(selectAIPProps);
    const currentFileList = useSelector(selectCurrentFileList);
    const serviceOptions = useSelector(selectServiceOptions);
    const {services,languages,formats} = serviceOptions;

    const modalFieldsRef = useRef(modalFields);
    const currentFileListRef = useRef(currentFileList);
    
    const projectFormServices = useSelector(selectProjectFormServices);
    const projectFormServicesRef = useRef(projectFormServices);
    if (AIPProps == null || AIPProps.serviceModal == null) return;
    const {serviceModal:{icon,size,label}} = AIPProps;
    const sourcelanguage = modalFields?.sourcelanguage ? modalFields.sourcelanguage.value : "1";
    const notes = modalFields?.notes ? modalFields.notes.value : "";
    const hasFileRows = currentFileList?.rows.length ? true : false;

    const setProjectFormServicesRef = data => {
        projectFormServicesRef.current = data;
        dispatch(setProjectFormServices(data));
    };
    const updateAIPProps = () => {
        console.log(AIPProps);
        dispatch(setAIPProps({...AIPProps,serviceModal:{...AIPProps.serviceModal,isVisible:false}}));
    }
    const updateModalFields = (data) => dispatch(setModalFields(data));

    const setModalFieldsRef = data => {
        modalFieldsRef.current = data;
        updateModalFields(data);  
    };
    
    const updateCurrentFileList = data => dispatch(setCurrentFileList(data));

    const setCurrentFileListRef = data => {
        currentFileListRef.current = data;
        updateCurrentFileList(data);
    };

    const updateSubmitData = () => {
        const formattedData = formatData(modalFields);
        const tableData = buildTableData(formattedData,services,languages,formats);
        setProjectFormServicesRef(
            {
                header:tableData.header,
                widths:tableData.widths,
                rows:[...projectFormServicesRef.current.rows,tableData.row],
                files:[...projectFormServicesRef.current.files,...formattedData.files],
                rawfiles:[...projectFormServicesRef.current.rawfiles,...formattedData.rawfiles], 
                data:[...projectFormServicesRef.current.data,{delivery:formattedData.delivery,dest:formattedData.dest,services:formattedData.services,sourcelanguage:formattedData.sourcelanguage,notes:modalFields.notes,filenames:formattedData.files.map(file=>file.name)}]
            }
        );
        updateAIPProps();
        setModalFieldsRef({sourcelanguage:{value:1,isChecked:false}});
        setCurrentFileListRef({...currentFileList,rows:[]});
    }
    const handleSubmit = async (event) => {
        event.preventDefault();
        updateSubmitData();
    };
    const updateChangeData = (name,value,isChecked) => {
        setModalFieldsRef({...modalFields,[name]:{value,isChecked}});
    };
    const handleChange = (event) => {
        const {name,value} = event.target;
        const isChecked = (event.target.type === "checkbox") ? event.target.checked : false;
        updateChangeData(name,value,isChecked);
    };
    const updateTrashClickData = (removeName) => {
        
        setCurrentFileListRef({...currentFileListRef.current,rows:currentFileListRef.current.rows.filter(ele=>ele[0] !== removeName)});
        setModalFieldsRef({...modalFieldsRef.current,files:modalFieldsRef.current.files.filter(ele=>ele.name !== removeName),rawfiles:modalFieldsRef.current.rawfiles.filter(ele=>ele.filename !== removeName)});
    };
    const handleTrashClick = (event) => {
        const removeName = event.target.getAttribute('data-remove');
        updateTrashClickData(removeName);
    };
    const updateFileData = (filelist) => {
        const files = modalFields.files ? [...modalFields.files] : [];
        const rawfiles = modalFields.rawfiles ? [...modalFields.rawfiles] : [];
        const fileRows = [...currentFileList.rows];
        const tstyle = {cursor:"pointer"};
        const excluded = [];
        if (!filelist.length) return;
        for(let i=0;i<filelist.length;i++) {
            const ext = filelist[i].name.split('.').pop();
            if (!checkExtension(ext)) {
                excluded.push(ext);
                continue;
            }
            rawfiles.push({file:filelist[i],filename:filelist[i].name});
            files.push({
                name:filelist[i].name,
                size:filelist[i].size,
                type:filelist[i].type,
                ext
            });
            fileRows.push([filelist[i].name,<div onClick={handleTrashClick} data-remove={filelist[i].name} style={tstyle}><Icon icon="fa fa-trash-o" data-remove={filelist[i].name} /></div>]);
        }
        if (excluded.length) dispatch(setErrors([`Some files were not inluded in the upload list. Files with the extension(s) ${excluded.join(", ")} are excluded.`]));
        setCurrentFileListRef({...currentFileList, rows:fileRows});
        setModalFieldsRef({...modalFields,files,rawfiles});
    };
    const handleFiles = (event) => {
        updateFileData(event.target.files);
        event.target.value = null;
    }
    return (
        <Modal modalId="serviceModal" width="800" height="860">
            <SectionTitle icon={icon} size={size}>{label}</SectionTitle>
            <Form onSubmit={handleSubmit}>
                <RowInner>
                    <CheckboxSection label="Services" data={services.map(ele=>({name:`services_${ele.service}`,value:ele.serviceID,label:ucFirst(ele.service), desc:ele.desc}))} onChange={handleChange} />
                </RowInner>
                <RowInner>
                    <label>Source Language<br />
                        <InputSelect onChange={handleChange} options={languages.map(ele=>({name:`src_${ele.languageName}`,value:ele.languageID,label:ucFirst(ele.languageName)}))} name="sourcelanguage" currentValue={sourcelanguage} />
                    </label>
                </RowInner>
                <RowInner>
                    <CheckboxSection height="130" label="Destination Languages" data={languages.map(ele=>({name:`dest_${ele.languageName}`,value:ele.languageID,label:ucFirst(ele.languageName)}))} onChange={handleChange} />
                </RowInner>
                <RowInner>
                    <CheckboxSection label="Delivery Format" data={formats.map(ele=>({name:`delivery_${ele.format}`,value:ele.formatID,label:ucFirst(ele.format),desc:ele.desc}))} onChange={handleChange} />
                </RowInner>
                <RowInner>
                  <ColHalf>
                    <label>Files<br />
                        <InputFile name="uploadfiles" onChange={handleFiles} />
                    </label>
                    {
                        hasFileRows && <InputFileList tableData={currentFileList} />
                    }
                  </ColHalf>
                  <ColHalf>
                    <label>Notes / Instructions<br />
                        <Textarea name="notes" value={notes} height="190" width="100%" onChange={handleChange} />
                    </label>
                  </ColHalf>
                </RowInner>
                <RowInner>
                    <FormButton type="submit">
                        <Icon icon="fa fa-plus-square" color="#FFF" /><ButtonText text="Add Services" color="#FFF" />
                    </FormButton>
                </RowInner>
            </Form>
        </Modal>
    );
};
export default ServiceModal;