import { Fragment } from "react";
import { useSelector } from "react-redux";
import { CheckboxRow, CheckboxSectionStyles, CheckboxSectionContainer } from "./checkbox-section.styles";
import Input from "../input/input.component";
import { selectModalFields } from "../../store/modal/modal.selector";

const CheckboxSection = ({data,label,onChange,height="100"}) => {
    const modalFields = useSelector(selectModalFields);
    return (
        <Fragment>
        <label>{label}</label><br />
            <CheckboxSectionContainer height={height}>
                <CheckboxSectionStyles>
                    {
                        data.map((ele,idx)=>{
                            const isChecked = modalFields[ele.name] ? modalFields[ele.name].isChecked : false;
                            return (
                                <CheckboxRow key={idx}><Input type="checkbox" width="30" name={ele.name} onChange={onChange} value={ele.value} checked={isChecked}/> {ele.label}</CheckboxRow>
                            );
                        })
                    }
                </CheckboxSectionStyles>
            </CheckboxSectionContainer>
        </Fragment>
        
    );
};
export default CheckboxSection;