import Row from "../../components/row/row.component";
import LoginBox from "../../components/login-box/login-box.component";

const Login = () => {
    return (
        <Row>
            <LoginBox />
        </Row>
    );
};
export default Login;